import { useState } from 'react'

import { api } from '../services/api.service'

import Card from '../components/card'

import { CurrencyField, TextAreaField } from './fields'

export default function InvestForm(props) {
    const [values, setValues] = useState({
        amountInDollars: '',
        notes: '',
    })

    return (
        <Card className='max-w-2xl p-6 rounded-3xl'>
            <form
                onSubmit={(event) => {
                    event.preventDefault()
                    api(`${process.env.REACT_APP_API_URL}/app/investment-request`, {
                        companyId: props.companyId,
                        amount: values.amountInDollars,
                        notes: values.notes,
                    }).then((res) => {
                        if (res.status === 'success') {
                            props.setShowInvestModal(false)
                            props.setShowInvestmentConfirmationModal(true)
                        }
                    })
                }}
            >
                <CurrencyField labelClass='text-black text-left p-2 text-lg font-medium' label='How much do you want to invest?' value={values.amountInDollars} onChange={(val) => setValues({ ...values, amountInDollars: val })}></CurrencyField>
                <TextAreaField labelClass='text-black text-left' label='Notes' value={values.notes} onChange={(val) => setValues({ ...values, notes: val })}></TextAreaField>
                <br />
                <div className='text-xs text-left'>While DreamRocket is in beta all investments will be handled off platform. After you confirm your investment intent we will reach out to you directly to facilitate your investment. Once DreamRocket is out of beta all investments will be handled directly on the platform!</div>
                <br />

                <button className='px-10 py-3 m-auto font-normal text-white rounded-lg DreamRocket-blue-background' type='submit'>
                    Confirm Investment
                </button>
            </form>
        </Card>
    )
}
