import { Bars3Icon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { api } from '../services/api.service'



export default function AppLayout() {
    return (
        <div className='flex flex-col w-screen h-[100dvh]  bg-gradient-to-b to-blue-900 from-blue-600'>
            <AppHeader></AppHeader>
            <main className='flex justify-center flex-1 w-full mx-auto overflow-auto max-w-none lg:max-w-5xl'>
                <Outlet></Outlet>
            </main>
            <AppFooter></AppFooter>
        </div>
    )
}

function AppHeader() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const [userType, setUserType] = useState("Investor")

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/user-type`).then((x) => {
            setUserType(x.type)
        })
    }, [])

    const NAV_LINKS = userType != "Company" ? [
        { text: 'Discover Pitches', link: '/' },
        { text: 'Find a Dream', link: '/search' },
        { text: 'My Investments', link: '/investments' },
        { text: 'Profile', link: '/profile' }] : [
        { text: 'Dashboard', link: '/dashboard' },
        { text: 'My Dream', link: '/dreamer/my-dream' }]


    return (
        <>
            <div className='sticky top-0 left-0 right-0 z-50 items-center justify-between hidden px-6 py-2 bg-blue-400 rounded-b-full shadow-md select-none sm:flex'>
                <div className="flex items-center justify-between w-full max-w-5xl py-5 mx-auto">
                    {/* Brand */}
                    <div className='flex items-center cursor-pointer' onClick={() => navigate('/')}>
                        <img src='/logo.webp' className="w-auto h-12" width={96} height={96} alt='DreamRocket' />
                    </div>

                    {/* Header Nav */}
                    <div className='hidden sm:block'>
                        <nav className='flex items-center space-x-6'>
                            {NAV_LINKS.map((d) => (
                                <NavLink key={d.link} to={d.link} className='font-medium text-neutral-100 hover:text-white'>
                                    {d.text}
                                </NavLink>
                            ))}

                            <NavLink to='/logout' className='font-medium rounded text-neutral-100 hover:text-white'>
                                Log Out
                            </NavLink>
                        </nav>
                    </div>

                </div>
            </div>
            <div className='sticky top-0 left-0 right-0 z-50 flex items-center justify-between px-6 py-2 select-none DreamRocket-blue-background sm:hidden '>
                <div className="flex items-center w-full max-w-5xl py-5 mx-auto text-white">


                    <div className='shrink-0'>
                        <div className='cursor-pointer' onClick={() => setOpen(true)}>
                            <Bars3Icon className='w-8 h-8' />
                        </div>
                        {open && (
                            <div className='fixed top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.2)]' onClick={() => setOpen(false)}>
                                <div className='fixed top-0 bottom-0 left-0 flex flex-col px-6 py-2 space-y-10 border-l shadow-2xl DreamRocket-blue-background min-w-fit' onClick={(e) => e.stopPropagation()}>
                                    <div className='flex w-full'>
                                        <div className='flex items-center flex-1 cursor-pointer' onClick={() => navigate('/')}>
                                            <img src='/logo.webp' className="w-auto h-6 mr-10" alt='DreamRocket' />
                                        </div>
                                        <div className='cursor-pointer h-[72px] flex' onClick={() => setOpen(false)}>
                                            <XMarkIcon className='w-8 h-8 my-auto text-black' />
                                        </div>
                                    </div>
                                    <nav className='flex flex-col px-2 space-y-4' onClick={() => setOpen(false)}>
                                        {NAV_LINKS.map((d) => (
                                            <NavLink key={d.link} to={d.link} className='font-bold text-neutral-100 hover:text-blue-700'>
                                                {d.text}
                                            </NavLink>
                                        ))}
                                        <div>
                                            <NavLink to='/logout' className='px-3 py-1 font-bold text-blue-700 bg-white border rounded border-slate-500 btn-hover'>
                                                Logout
                                            </NavLink>
                                        </div>


                                    </nav>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <div className="text-xl font-semibold text-center">
                        Discover Pitches
                    </div> */}
                    <div className="flex-1 mx-8">
                        <img src="/logo.webp" className="" />

                    </div>
                    <div className='shrink-0'>
                        <div className='cursor-pointer' onClick={() => navigate('/profile')}>
                            <UserIcon className='w-8 h-8' />
                        </div>

                    </div>

                </div>
            </div>
        </>

    )
}

function AppFooter() {
    return <></>
}
