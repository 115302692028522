import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ImageViewer from '../components/image-viewer'

import { CurrencyField, NumberField, TextAreaField, TextField } from './fields'
import DownloadFile from './fields/download-file'
import ImageField from './fields/image-upload'

export default function CompanyReviewForm(props) {
    const [values, setValues] = useState({
        isRejected: true,
        rejectionComments: '',

        name: '',
        logo: '',
        city: '',
        country: '',
        founders: [],
        websiteUrl: '',
        socialMediaLinks: [],
        stage: '',
        industry: [],
        targetMarket: '',
        slogan: '',
        description: '',
        fundingSought: '',
        fundingBreakdown: [],
        equityOffered: '',
        valuation: '',
        revenue: '',
        relevantExperience: '',
        images: [],
        demoVideoUrl: '',
        businessModel: '',
        pitchDeck: '',
        milestones: '',
        businessRegistrationDetails: '',
        intellectualPropertyList: '',
        missionStatement: '',
        videoUrl: '',
    })

    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        setValues({ ...values, ...props.companyData })
    }, [])

    return (
        <form className='max-w-5xl p-5 mx-auto my-5 bg-white rounded shadow h-fit 2xl:max-w-6xl'>
            <>
                <TextField label='Name' disabled={true} value={values.name}></TextField>

                {values.logo && 
                    <>
                        <ImageViewer w={200} image={values.logo} className='mx-auto mt-4' />
                        <div className='text-center '>Logo</div>
                    </>
                }
                {!values.logo && <div className='mt-6 text-center'>Company has not uploaded a logo</div>}
                <div className="grid grid-cols-2 gap-4">

                    <TextField label='City' disabled={true} value={values.city}></TextField>
                    <TextField label='Country' disabled={true} value={values.country}></TextField>
                </div>
                <div>Founders</div>
                {values.founders?.map((x, i) => (
                    <TextField label={`Founder ${i + 1} name`} disabled={true} value={values.founders[i].name}></TextField>
                ))}
                <TextField label='Website Url' disabled={true} value={values.websiteUrl}></TextField>
                <div>Social Media Links</div>
                {values.socialMediaLinks.length ? (
                    values.socialMediaLinks.map((x, i) => (
                        <div className='flex items-center space-x-4'>
                            <TextField className='flex-1' label={`Link ${i + 1}`} disabled={true} value={x.url}></TextField>
                        </div>
                    ))
                ) : (
                    <div>None</div>
                )}
                <TextField label='Stage' disabled={true} value={values.stage}></TextField>
                <TextField label='Industry/Vertical' disabled={true} value={values.industry.join(', ')}></TextField>
                <TextAreaField label='Target Market' disabled={true} value={values.bio} />
                <TextAreaField label='Slogan' disabled={true} value={values.slogan} />
                <TextAreaField label='Description' disabled={true} value={values.description} />
                <CurrencyField label='Funding Sought' disabled={true} value={values.fundingSought} />
                <div>Funding Breakdown Slices</div>
                {values.fundingBreakdown.length > 0 ? (
                    values.fundingBreakdown.map((x, i) => (
                        <div className='flex items-center space-x-4'>
                            <TextField className='flex-1' label={`Slice ${i + 1} Name`} disabled={true} value={x.name}></TextField>
                            <TextField className='flex-1' label={`Slice ${i + 1} Size (%)`} disabled={true} value={x.slice}></TextField>
                        </div>
                    ))
                ) : (
                    <div>None</div>
                )}
                <NumberField label='Equity Offered' disabled={true} value={values.equityOffered} />
                <NumberField label='Valuation' disabled={true} value={values.valuation} />
                <NumberField label='Revenue' disabled={true} value={values.revenue} />
                <TextAreaField label='Relevant Experience' disabled={true} value={values.relevantExperience} />
                <div>Images</div>
                {values.images.length > 0 ? (
                    values.images.map((x, i) => (
                        <div className='flex items-center space-x-4'>
                            <ImageField className='flex-1' label={`Image ${i + 1}`} value={x} />
                        </div>
                    ))
                ) : (
                    <div>None</div>
                )}
                <TextField label='Video Demo URL' disabled={true} value={values.demoVideoUrl}></TextField>
                <TextAreaField label='Milestones' disabled={true} value={values.milestones} />
                <TextAreaField label='Business Registration Details' disabled={true} value={values.businessRegistrationDetails} />
                <label className={`block leading-6 text-neutral-700 text-sm font-medium`}>Business Model</label>
                {values.businessModel ? <DownloadFile url={values.businessModel} name='Business Model' className='mx-auto' /> : <div>None</div>}
                <label className={`block leading-6 text-neutral-700 text-sm font-medium`}>Pitch Deck</label>
                {values.pitchDeck ? <DownloadFile url={values.pitchDeck} name='Pitch Deck' className='mx-auto' /> : <div>None</div>}
                <TextAreaField label='Intellectual Property List' disabled={true} value={values.intellectualPropertyList} />
                <TextAreaField label='Mission Statement' disabled={true} value={values.missionStatement} />
                <label>Video</label>
                <div className='flex justify-center'>{values.videoUrl && values.videoUrl !== '' && <video className='w-96' controls src={`https://ik.imagekit.io/verticode/dreamrocket/${values.videoUrl.split('/').pop()}`} />}</div>
                --- Reviewer ---
                <TextAreaField label='Rejection Comments' value={values.rejectionComments} onChange={(val) => setValues({ ...values, rejectionComments: val })} />
                <div className='flex justify-evenly'>
                    <button className='inline-flex items-center justify-center w-24 px-4 py-2 mt-5 text-sm font-medium text-white bg-blue-500 border border-transparent rounded shadow-sm cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2' onClick={() => props.setApprovalStatus('true', {})}>
                        Approve
                    </button>
                    <button className='inline-flex items-center justify-center w-24 px-4 py-2 mt-5 text-sm font-medium text-white bg-red-500 border border-transparent rounded shadow-sm cursor-pointer hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2' onClick={() => props.setApprovalStatus('false', values.rejectionComments)}>
                        Reject
                    </button>
                </div>
            </>
        </form>
    )
}
