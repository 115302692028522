import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate, useSearchParams } from 'react-router-dom'

import BlockScrollContainer from '../components/block-scroll-container'
import Button from '../components/button'
import Loading from '../components/loading'
import FormModal from '../forms/form-modal'
import { api } from '../services/api.service'

export default function Home() {
    const [data, setData] = useState()
    const navigate = useNavigate()
    const [videos, setVideos] = useState()
    const [searchParams, setSearchParams] = useSearchParams();

    const childRef = useRef(null)

    const getMap = () => {
        if (!childRef.current) {
            childRef.current = new Map()
        }

        return childRef.current
    }

    const getNode = (nodeId) => {
        const map = getMap()
        const node = map.get(nodeId)
        return node
    }

    const getRef = (node, index) => {
        const map = getMap()
        if (node) {
            map.set(index, node)
        } else {
            map.delete(index)
        }
    }

    const viewVideo = (id) => {
        api(`${process.env.REACT_APP_API_URL}/app/video-view/${id}`).then((x) => { })
    }

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => {
            setData(x)

            const unsortedVideos = x.companies.map((z) => {
                return { url: z.videoUrl?.split('/')[z.videoUrl?.split('/').length - 1], id: z._id, name: z.name, fundingSought: z.fundingSought, fundingReceived: z.fundingReceived, valuation: z.valuation, views: x.videoViews.find(y => y.companyId == z._id)?.views }
            })

            const unwatchedVideos = []
            const watchedVideos = []

            unsortedVideos.forEach((video) => {
                if (video.views > 0) {
                    watchedVideos.push(video)
                } else {
                    unwatchedVideos.push(video)
                }
            })
            const sortedVideos = [...unwatchedVideos.sort((a, b) => new Date(b.approvedAt) - new Date(a.approvedAt)), ...watchedVideos.sort((a, b) => b.views - a.views)]

            setVideos(sortedVideos)
        })
    }, [])

    function nFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    if (!data || !videos) return <Loading></Loading>

    return (
        <div className='w-full max-w-md mx-auto'>
            <FormModal open={!!searchParams.get('fv')} setOpen={() => setSearchParams({})}>
                <div className="p-10 bg-white rounded-lg shadow">
                    <div className="my-3 text-2xl font-medium text-neutral-800">🚀 Welcome to DreamRocket!</div>
                    <div className="my-3 text-xl font-medium text-neutral-800">Here's how it works:</div>
                    <div className="my-3 font-medium text-neutral-700">1️⃣ Scroll through startup pitches.</div>
                    <div className="my-3 font-medium text-neutral-700">2️⃣ View Funding Progress.</div>
                    <div className="my-3 font-medium text-neutral-700">3️⃣ Show your intent to invest with a tap.</div>
                    <div className="my-3 font-bold text-neutral-700">Happy exploring!</div>
                    <div className="my-3 font-light text-neutral-500">Note: In-App Investing Coming Soon</div>
                    <Button onClick={() => setSearchParams({})}>Start Searching</Button>
                </div>
            </FormModal>
            <BlockScrollContainer markVideoView={(index) => viewVideo(videos[index].id)} getNode={getNode}>
                {(childIndex) =>
                    videos.map((x, index) => {
                        return (
                            <div key={index} name={`bc-${index}`} ref={(node) => getRef(node, index)} className={`flex flex-col relative h-full w-full overflow-hidden bg-black`}>
                                <div className="flex flex-col flex-1 max-h-full">
                                    <ReactPlayer className="m-auto" playsinline width="auto" height={'100%'} playing={!searchParams.get('fv') && index == childIndex} url={`https://ik.imagekit.io/verticode/dreamrocket/${x.url}`} title={x.name} muted={false} />
                                    <div className='absolute bottom-0 w-full p-4 bg-gradient-to-t from-black'>
                                        <div className='grid grid-cols-2'>
                                            <div className='mb-2 text-2xl font-semibold text-white'>{x.name}</div>
                                            <div onClick={() => navigate('/company/' + x.id)} className='self-start py-3 text-center text-white rounded-full cursor-pointer px-7 justify-self-center DreamRocket-blue-background'>
                                                INVEST NOW
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mb-3 text-sm text-white'>Valuation - ${nFormatter(x.valuation)}</div>
                                            <div className='text-sm text-white'>{x.slogan}</div>
                                        </div>
                                        <InvestmentProgressBar data={x} nFormatter={nFormatter} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </BlockScrollContainer>
        </div>
    )
}

function InvestmentProgressBar({ data, nFormatter }) {
    return (
        <div className='flex items-center justify-center w-[100%] pt-2'>
            <div className='px-2 py-1 mx-2 text-sm text-white rounded-full'>$0</div>
            <div className='flex-1 bg-white border-gray-300 rounded-md border-1'>
                <div className='bg-blue-500 rounded-l-md' style={{ width: `${(data.fundingReceived / data.fundingSought * 100).toFixed() > 100 ? 100 : (data.fundingReceived / data.fundingSought * 100).toFixed()}%` }}>
                    <div className='w-full pr-2 text-sm text-right text-white'>
                        {(data.fundingReceived / data.fundingSought * 100).toFixed()}%
                    </div>
                </div>
            </div>
            <div className='px-2 py-1 mx-2 text-sm text-white rounded-full'>${nFormatter(data.fundingSought)}</div>
        </div>
    )
}