import { useNavigate, useParams } from 'react-router-dom'

import PageHeader from '../components/page-header'

import { CurrencyField, FileField, MultiSelectField, NumberField, SelectField, TextAreaField, TextField } from './fields'
import ImageField from './fields/image-upload'
import S3Field from './fields/s3-field'
import FormWrapper from './form-wrapper'

export default function CompanyForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    return (
        <div>
            <PageHeader headline='Manage Company'></PageHeader>
            <FormWrapper url='admin/company' defaultValue={{ founders: [{}], socialMediaLinks: [{}], fundingBreakdown: [{}], images: [''] }} id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <TextField label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>

                        <ImageField label='Logo' value={values.logo} onChange={(val) => setValues({ ...values, logo: val })} />

                        <div className='grid grid-cols-2 gap-4'>
                            <SelectField optionListName={'countries'} label='Country' value={values.country} onChange={(val) => setValues({ ...values, country: val })} />
                            <TextField label='City' value={values.city} onChange={(val) => setValues({ ...values, city: val })}></TextField>
                        </div>

                        <div>Founders</div>
                        {values.founders.map((x, i) => (
                            <div className='flex items-center space-x-4'>
                                <TextField className='flex-1' label={`Founder ${i + 1} name`} value={x.name} onChange={(val) => setValues({ ...values, founders: values.founders.map((y, j) => (j == i ? { ...y, name: val } : y)) })}></TextField>
                                <div className='text-xl font-bold cursor-pointer' onClick={() => setValues({ ...values, founders: values.founders.filter((y, j) => j !== i) })}>
                                    X
                                </div>
                            </div>
                        ))}
                        <div className='cursor-pointer' onClick={() => setValues({ ...values, founders: values.founders.concat([{}]) })}>
                            + Add Founder
                        </div>
                        <TextField label='Website URL' value={values.websiteUrl} onChange={(val) => setValues({ ...values, websiteUrl: val })}></TextField>
                        <div>Social Media Links</div>

                        {values.socialMediaLinks.map((x, i) => (
                            <div className='flex items-center space-x-4'>
                                <TextField className='flex-1' label={`Link ${i + 1}`} value={x.url} onChange={(val) => setValues({ ...values, socialMediaLinks: values.socialMediaLinks.map((y, j) => (j == i ? { ...y, url: val } : y)) })}></TextField>
                                <div className='text-xl font-bold cursor-pointer' onClick={() => setValues({ ...values, socialMediaLinks: values.socialMediaLinks.filter((y, j) => j !== i) })}>
                                    X
                                </div>
                            </div>
                        ))}
                        <div className='cursor-pointer' onClick={() => setValues({ ...values, socialMediaLinks: values.socialMediaLinks.concat([{}]) })}>
                            + Add Link
                        </div>

                        <SelectField optionListName={'stage'} label='Stage' value={values.stage} onChange={(val) => setValues({ ...values, stage: val })} />
                        <MultiSelectField required optionListName={'industry'} label='Industry/Vertical' value={values.industry} onChange={(val) => setValues({ ...values, industry: val })} />

                        <TextAreaField label='Target Market' value={values.bio} onChange={(val) => setValues({ ...values, bio: val })} />
                        <TextAreaField label='Slogan' value={values.slogan} onChange={(val) => setValues({ ...values, slogan: val })} />
                        <TextAreaField label='Description' value={values.description} onChange={(val) => setValues({ ...values, description: val })} />
                        <div className="grid grid-cols-2 gap-4">
                            <CurrencyField label='Funding Sought' value={values.fundingSought} onChange={(val) => setValues({ ...values, fundingSought: val })} />
                            <CurrencyField label='Funding Received' value={values.fundingReceived} onChange={(val) => setValues({ ...values, fundingReceived: val })} />
                        </div>
                        <div>Funding Breakdown Slices</div>
                        {values.fundingBreakdown.map((x, i) => (
                            <div className='flex items-center space-x-4'>
                                <TextField className='flex-1' label={`Slice ${i + 1} Name`} value={x.name} onChange={(val) => setValues({ ...values, fundingBreakdown: values.fundingBreakdown.map((y, j) => (j == i ? { ...y, name: val } : y)) })}></TextField>
                                <TextField className='flex-1' label={`Slice ${i + 1} Size (%)`} value={x.slice} onChange={(val) => setValues({ ...values, fundingBreakdown: values.fundingBreakdown.map((y, j) => (j == i ? { ...y, slice: val } : y)) })}></TextField>
                                <div className='text-xl font-bold cursor-pointer' onClick={() => setValues({ ...values, fundingBreakdown: values.fundingBreakdown.filter((y, j) => j !== i) })}>
                                    X
                                </div>
                            </div>
                        ))}
                        <div className='cursor-pointer' onClick={() => setValues({ ...values, fundingBreakdown: values.fundingBreakdown.concat([{}]) })}>
                            + Add Breakdown Item
                        </div>
                        <NumberField label='Equity Offered' value={values.equityOffered} onChange={(val) => setValues({ ...values, equityOffered: val })} />
                        <NumberField label='Valuation' value={values.valuation} onChange={(val) => setValues({ ...values, valuation: val })} />
                        <NumberField label='Revenue' value={values.revenue} onChange={(val) => setValues({ ...values, revenue: val })} />

                        <TextAreaField label='Relevant Experience' value={values.relevantExperience} onChange={(val) => setValues({ ...values, relevantExperience: val })} />

                        <div>Images</div>
                        {values.images.map((x, i) => (
                            <div className='flex items-center space-x-4'>
                                <ImageField className='flex-1' label={`Image ${i + 1}`} value={x} onChange={(val) => setValues({ ...values, images: values.images.map((y, j) => (j == i ? val : y)) })} />
                                <div className='text-xl font-bold cursor-pointer' onClick={() => setValues({ ...values, images: values.images.filter((y, j) => j !== i) })}>
                                    X
                                </div>
                            </div>
                        ))}
                        <div className='cursor-pointer' onClick={() => setValues({ ...values, images: values.images.concat(['']) })}>
                            + Image
                        </div>

                        <TextField label='Video Demo URL' value={values.demoVideoUrl} onChange={(val) => setValues({ ...values, demoVideoUrl: val })}></TextField>

                        <TextAreaField label='Milestones' value={values.milestones} onChange={(val) => setValues({ ...values, milestones: val })} />
                        <TextAreaField label='Business Registration Details' value={values.businessRegistrationDetails} onChange={(val) => setValues({ ...values, businessRegistrationDetails: val })} />
                        <FileField label='Business Model' value={values.businessModel} onChange={(val) => setValues({ ...values, businessModel: val })} />
                        <FileField label='Pitch Deck' value={values.pitchDeck} onChange={(val) => setValues({ ...values, pitchDeck: val })} />

                        <TextAreaField label='Intellectual Property List' value={values.intellectualPropertyList} onChange={(val) => setValues({ ...values, intellectualPropertyList: val })} />
                        <TextAreaField label='Mission Statement' value={values.missionStatement} onChange={(val) => setValues({ ...values, missionStatement: val })} />

                        <S3Field label='Video' value={values.videoUrl} onChange={(val) => setValues({ ...values, videoUrl: val })} />
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
