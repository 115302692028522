import { useEffect, useRef, useState } from 'react'
import { Events, scroller, scrollSpy } from 'react-scroll'

export default function BlockScrollContainer({ className = '', children, getNode, markVideoView }) {
    const [childIndex, setChildIndex] = useState(0)
    const [containerHeight, setContainerHeight] = useState(null)
    const [containerBorderTop, setContainerBorderTop] = useState(null)

    const containerRef = useRef(null)

    const SCROLL_THRESHOLD = 10

    const [autoScrollActive, setAutoScrollActive] = useState(false)

    const [windowDimensions, setWindowDimensions] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        scroller.scrollTo(`bc-${childIndex}`, {
            duration: 500,
            delay: 0,
            smooth: 'smooth',
            ignoreCancelEvents: true,
            isDynamic: true,
            containerId: 'scroll-container',
            spy: 'true'
        })
    }, [childIndex])

    useEffect(() => {
        Events.scrollEvent.register('begin', function (to, element) {
            setAutoScrollActive(true)
        });
        Events.scrollEvent.register('end', function () {
            setAutoScrollActive(false)
        })
        scrollSpy.update();
        return () => {
            Events.scrollEvent.remove('end')
            Events.scrollEvent.remove('begin')
        }
    }, [])

    useEffect(() => {
        if (containerRef.current) {
            setContainerBorderTop(parseFloat(containerRef.current.getBoundingClientRect().y))
            setContainerHeight(parseFloat(getComputedStyle(containerRef.current).height))
        }
    }, [containerRef.current, windowDimensions])

    const handleSetChildIndex = (number) => {
        setChildIndex(childIndex + number)
        //mark video scrolled away from as viewed
        markVideoView(childIndex)
    }

    const handleScroll = (e) => {
        const childY = getNode(childIndex).getBoundingClientRect().y - containerBorderTop
        let childYPercentage = (Math.abs(getNode(childIndex).getBoundingClientRect().y - containerBorderTop) / containerHeight) * 100

        if (childY < 0) childYPercentage = childYPercentage * -1
        if (childYPercentage > SCROLL_THRESHOLD) {
            handleSetChildIndex(-1)
        }
        else if (childYPercentage < -SCROLL_THRESHOLD) {
            handleSetChildIndex(1)
        }
    }

    return (
        <div
            id='scroll-container'
            className={`overflow-y-scroll no-scrollbar h-full  ${className ?? ''}`}
            ref={containerRef}
            onScroll={() => {
                if (!autoScrollActive) {
                    handleScroll()
                }
            }}
        >
            {children(childIndex)}
        </div>
    )
}
