import { CheckIcon, InformationCircleIcon, LinkIcon, MapPinIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'
import { api } from '../services/api.service'

export default function DreamerDashboard() {
  const [data, setData] = useState()
  const params = useParams();
  const id = params.id;

  const update = () => {
    api(`${process.env.REACT_APP_API_URL}/app/user/dashboard`).then((x) => {
      setData(x)
    })
  }
  useEffect(() => {
    update()
  }, [])

  if (!data) return <Loading></Loading>
  if (data.status) return <DraftCompany status={data.status} />

  return (
    <div className='w-full p-10 m-6 bg-white rounded-lg shadow-md md:grid md:grid-rows-[auto,_3fr] md:grid-cols-[2fr,_1fr] md:gap-x-10 overflow-auto'>
      <div className=''>
        <div className="flex items-center space-x-4">
          {data.company.logo && <ImageViewer image={data.company.logo} className='inline w-24 col-start-1 row-span-2' />}
          <h1 className='self-end inline-block col-start-2 text-3xl font-bold'>{data.company.name}</h1>
        </div>

        <p className='self-start col-start-2 my-2 text-sm italic'>{data.company.slogan}</p>
      </div>

      <div className='flex flex-col col-start-1 row-start-2'>
        <DetailsCard data={data.company} update={update} />
        <InvestmentCard data={data.userInvestments} />
      </div>

      <StageSideBar className='hidden row-span-2 pl-16 border-l place-self-start md:block border-gray-900/5' currentStep={steps.findIndex((x) => x.name === data.company.stage)} />

    </div>
  )
}

function DraftCompany(props) {
  const navigate = useNavigate()
  return (
    <div className='flex flex-col items-center justify-center w-full'>
      <div className='flex items-center flex-1 p-10 m-10 bg-white rounded-xl'>
        <h1 className='flex-1 text-2xl text-center'>{props.status}</h1>
      </div>
    </div>
  )
}

function DetailsCard({ data, update }) {
  const navigate = useNavigate()
  return (
    <div className="lg:col-start-3 lg:row-end-1">
      <h2 className="sr-only">Funding Sought</h2>
      <div>
        <dl className="flex flex-wrap pb-6">
          <div className='flex flex-col w-full'>
            <div className='flex flex-wrap'>
              <div className="flex-auto pt-6 pl-6">
                <dt className="text-sm font-semibold leading-6 text-gray-900">Funding Sought</dt>
                <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">${data.fundingSought}</dd>
              </div>
              {data.industry?.length > 0 && <div className="self-end flex-none px-6 pt-4">
                <dt className="sr-only">Industry</dt>
                <dd className="inline-flex items-center px-2 py-1 text-xs font-medium text-blue-700 rounded-md bg-blue-50 ring-1 ring-inset ring-blue-600/20">
                  {data.industry.join(', ')}
                </dd>
              </div>}

            </div>
            <div className='pt-6 pl-6'>
              <FundingBar update={update} data={data} />
            </div>
          </div>
          <div className="flex flex-none w-full px-6 pt-6 mt-4 border-t gap-x-4 border-gray-900/5">
            <dt className="flex-none">
              <span className="sr-only">Website address</span>
              <LinkIcon className="w-5 h-6 text-gray-400" aria-hidden="true" />
            </dt>
            <dd onClick={() => window.open(`https://${data.websiteUrl}`, '_blank')} className="text-sm font-medium leading-6 text-blue-700 underline cursor-pointer">{data.websiteUrl}</dd>
          </div>
          <div className="flex flex-none w-full px-6 mt-2 gap-x-4">
            <dt className="flex-none">
              <span className="sr-only">Status</span>
              <MapPinIcon className="w-5 h-6 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">{data.country}</dd>
          </div>
          <div className="flex flex-none w-full px-6 mt-2 gap-x-4">
            <dt className="flex-none">
              <span className="sr-only">Description</span>
              <InformationCircleIcon className="w-5 h-6 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              <time dateTime="2023-01-31">{data.description}</time>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

function FundingBar({ data, update }) {
  const [showAddFunding, setShowAddFunding] = useState(false)
  const [funding, setFunding] = useState(data.fundingReceived)

  const handleFundingInput = () => {
    if (!showAddFunding) {
      return setShowAddFunding(true)
    } else {
      api(`${process.env.REACT_APP_API_URL}/app/user/dashboard`, { data: { fundingReceived: +funding } }).then(() => update())
      return setShowAddFunding(false)
    }
  }

  return (
    <div>
      <h4 className="sr-only">Status</h4>
      <div className='grid grid-cols-2 gap-y-2 sm:flex sm:justify-between'>
        <p className="row-start-1 text-sm font-medium text-gray-900">Funding Progress</p>
        {showAddFunding && <input value={funding || 0} onChange={(e) => setFunding(e.target.value)} type='number' className="w-full col-span-2 p-0 pl-2 m-0 text-sm rounded-lg sm:w-36 border-slate-300" /> }
        <button onClick={handleFundingInput} className="inline-flex items-center row-start-1 px-2 py-1 mr-6 text-xs font-medium text-green-700 rounded-md bg-green-50 ring-1 ring-inset ring-green-600/20">{showAddFunding ? 'Save Update' : 'Update funding'}</button>
      </div>
      <div className="mt-6" aria-hidden="true">
        <div className="overflow-hidden bg-blue-200 rounded-full">
          <div className="h-2 bg-blue-600 rounded-full" style={{ width: `${data.fundingReceived / data.fundingSought * 100}%` || '0%' }} />
        </div>
        <div className="flex justify-between mt-6 text-sm font-medium text-gray-600">
          <div className={`text-left ${(data.fundingReceived / data.fundingSought * 100) >= 0 ? 'text-blue-600' : ''}`}>0%</div>
          <div className={`text-left ${(data.fundingReceived / data.fundingSought * 100) >= 25 ? 'text-blue-600' : ''}`}>25%</div>
          <div className={`text-left ${(data.fundingReceived / data.fundingSought * 100) >= 50 ? 'text-blue-600' : ''}`}>50%</div>
          <div className={`text-left ${(data.fundingReceived / data.fundingSought * 100) >= 75 ? 'text-blue-600' : ''}`}>75%</div>
          <div className={`text-left ${(data.fundingReceived / data.fundingSought * 100) >= 100 ? 'text-blue-600' : ''}`}>100%</div>
        </div>
      </div>
    </div>
  )
}

function InvestmentCard({ data }) {
  if (!data) return <p>No investments yet</p>
  return (
    <div className="pl-6 mt-2">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Investment Requests</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users who want to invest in your company.
          </p>
        </div>
      </div>
      <div className="-mx-4 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-900/5">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                User
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Amount
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Notes
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.length === 0 &&
              <tr>
                <td className='mt-4 text-sm text-gray-700'>No investments yet.</td>
              </tr>
            }
            {data.map((inv) => {
              return(
              <tr key={inv.user?.name}>
                <td className="w-full py-4 pl-4 pr-3 text-sm font-medium text-gray-900 max-w-0 sm:w-auto sm:max-w-none sm:pl-0">
                  {inv.user? inv.user.name : 'Anon.'}
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">Amount</dt>
                    <dd className="mt-1 text-gray-700 truncate">{inv.amount}</dd>
                    <dt className="sr-only sm:hidden">Notes</dt>
                    <dd className="mt-1 text-gray-500 truncate sm:hidden">{inv.notes}</dd>
                  </dl>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">${inv.amount}</td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{inv.notes}</td>
              </tr>
            )})}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const steps = [
  { name: 'Idea' },
  { name: 'Prototype' },
  { name: 'MVP' },
  { name: 'Revenue generating' },
  { name: 'Profitable' },
  { name: 'Scaling' },
]

function StageSideBar({ currentStep, className }) {
  return (
    <nav aria-label="Progress" className={className}>
      <ol role="list" className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
            {currentStep > stepIdx ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-500" aria-hidden="true" />
                ) : null}
                <div className="relative flex items-center group">
                  <span className="flex items-center h-9">
                    <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-blue-500 rounded-full">
                      <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                    </span>
                    <span className="flex flex-col min-w-0 ml-4">
                      <span className="text-sm font-medium">{step.name}</span>
                    </span>
                  </span>
                </div>
              </>
            ) : stepIdx === currentStep ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                ) : null}
                <div className="relative flex items-center group" aria-current="step">
                  <span className="flex items-center h-9" aria-hidden="true">
                    <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 border-blue-500 rounded-full">
                      <span className="h-2.5 w-2.5 rounded-full bg-blue-500" />
                    </span>
                  </span>
                  <span className="flex flex-col min-w-0 ml-4">
                    <span className="text-sm font-medium text-blue-500">{step.name}</span>
                  </span>
                </div>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                ) : null}
                <div className="relative flex items-center group">
                  <span className="flex items-center h-9" aria-hidden="true">
                    <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 border-gray-300 rounded-full">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent" />
                    </span>
                  </span>
                  <span className="flex flex-col min-w-0 ml-4">
                    <span className="text-sm font-medium text-gray-500">{step.name}</span>
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}