export default function CurrencyField({ label, value, onChange, className, inputClass, labelClass, onBlur, required, disabled }) {
    return (
        <div className={className + ' mt-4'}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </label>
            <div className='relative inline-block w-full mt-1 overflow-hidden rounded shadow-sm'>
                <div className='absolute inset-y-0 left-0 flex items-center p-3 border-r border-gray-200 pointer-events-none bg-slate-200'>
                    <span className='text-gray-500 sm:text-sm'>$</span>
                </div>

                <input disabled={disabled} required={required} onBlur={onBlur} type='number' value={value} onChange={(e) => onChange(e.target.value)} className={` w-full lock rounded-lg border-0 py-3 pl-12 pr-3 text-neutral-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-2 focus:ring-blue-400 sm:text-sm sm:leading-6 ${inputClass}`} placeholder='0.00' />

            </div>
        </div>
    )
}
