import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import ImageViewer from '../components/image-viewer'
import { TextField } from '../forms/fields'
import { api } from '../services/api.service'

export default function CompanySearch() {
    const [data, setData] = useState()
    const [query, setQuery] = useState()
    const navigate = useNavigate()

    const search = () => {
        api(`${process.env.REACT_APP_API_URL}/app/search/${query}`).then((x) => {
            setData(x)
        })
    }

    return (
        <div className='w-full max-w-lg p-10 mx-auto overflow-scroll bg-white'>
            <h3 className='my-2 text-2xl font-semibold leading-6 DreamRocket-blue-text'>Search</h3>
            <div className='flex justify-start'>
                <TextField className='flex-1 mt-0 mr-2' value={query} onChange={setQuery} />
                <button className='self-center flex-shrink px-5 py-2 text-lg text-white rounded-lg h-fit DreamRocket-blue-background' onClick={() => search()}>Search</button>
            </div>
            <ul role="list" className="mt-4 overflow-hidden bg-white divide-y divide-gray-100 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                {data && data.map((x) => (
                    <li key={x._id} onClick={() => navigate(`/company/${x._id}`)} className="relative flex justify-between px-4 py-5 gap-x-6 hover:bg-gray-100 hover:cursor-pointer sm:px-6">
                        <div className="flex min-w-0 gap-x-4">
                            <ImageViewer className="flex-none w-12 h-12 rounded-full bg-gray-50" image={x.logo} alt="company logo" />
                            <div className="flex-auto min-w-0">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                    
                                    <span className="absolute inset-x-0 bottom-0 -top-px" />
                                    {x.name}
                                    
                                </p>
                                <p className="flex mt-1 text-xs leading-5 text-gray-500">
                                    {x.city ? x.city + ', ': ''}{x.country} 
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center shrink-0 gap-x-4">
                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                                <p className="text-sm leading-6 text-gray-900">{x.stage}</p>
                            </div>
                            <ChevronRightIcon className="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}