import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { PasswordField, TextField } from '../forms/fields'

import AuthLayout from './layout'

export default function Register() {
    const [values, setValues] = useState({ type: 'Investor' })
    const [error, setError] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    async function submit() {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ user: values, metaData: { ref: searchParams.get('r'), client: searchParams.get('c') } }),
        })
            .then((res) => {
                return res.json()
            })
            .then((x) => {
                if (x.error) {
                    if (x.error === 'email_in_use') {
                        setError('Email already in use. Please sign in or register with a different email.')
                    }
                } else if (x.token) {
                    localStorage.setItem('token', x.token)
                    if (searchParams.get('rd')) {
                        window.location.replace(searchParams.get('rd'))
                    } else {
                        // Reroute depending on type of account
                        window.location.replace(values.type === 'Investor' ? '/onboarding' : '/dreamer/onboarding')
                    }
                } else {
                    setError('Registration failed. Please check your details and try again.')
                }
            })
    }

    return (
        <AuthLayout>
            <div className='text-xl font-medium text-center text-neutral-800'>Create your account</div>
            <div className='my-5 text-sm font-light text-center text-neutral-500'>
                Already have an account?{' '}
                <a className='text-blue-400 hover:text-blue-500' href='/login'>
                    Sign In
                </a>
            </div>
            <div className='space-y-6'>
                <div>
                    <label className='block text-sm font-medium text-center text-neutral-800'>{error}</label>
                </div>

                <TextField required label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>

                <TextField required email label='Email' value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>

                <div className='grid grid-cols-2 gap-4'>
                    <PasswordField required email label='Password' value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                    <PasswordField required email label='Confirm Password' value={values.confirmPassword} onChange={(val) => setValues({ ...values, confirmPassword: val })}></PasswordField>
                </div>

                <div className="flex space-x-4">
                    <div className="flex-1">
                        <div onClick={() => setValues({ ...values, type: 'Investor' })} className={`${values.type == 'Investor' ? 'bg-blue-100' : ''} flex-1 p-5 text-xl font-medium text-center border border-dashed rounded-lg border-neutral-500 text-neutral-800`}>I'm an Investor</div>
                        <div className="mt-1 text-xs text-center font-gray-600">Explore new frontiers and invest in innovative ideas as an Investor!</div>
                    </div>

                    <div className="flex-1">
                        <div onClick={() => setValues({ ...values, type: 'Company' })} className={`${values.type == 'Company' ? 'bg-blue-100' : ''} flex-1 p-5 text-xl font-medium text-center border border-dashed rounded-lg border-neutral-500 text-neutral-800`}>I'm a Dreamer</div>
                        <div className="mt-1 text-xs text-center font-gray-600">Blast off with your established business idea as a Dreamer! (Pre-Launch Gamified Environment Coming Soon)</div>

                    </div>

                </div>

                <div className='flex'>
                    <input checked={values.tos} onChange={(e) => setValues({ ...values, tos: e.target.checked })} type='checkbox' className='block appearance-none rounded border mt-0.5 border-gray-200  placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm' />

                    <div className='ml-2 text-sm text-light text-neutral-500'>
                        I accept the platform{' '}
                        <a target='_blank' href='/terms-of-service' className='text-blue-400 hover:text-blue-500'>
                            Terms of Service
                        </a>
                    </div>
                </div>

                <div>
                    <button onClick={() => submit()} type='button' disabled={!values.email || !values.password || values.password != values.confirmPassword || !values.type || !values.tos} className='flex justify-center w-full px-4 py-2 font-semibold text-white bg-blue-500 border border-transparent rounded shadow-sm disabled:bg-gray-500 text-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
                        Sign Up
                    </button>
                </div>
            </div>
        </AuthLayout>
    )
}
