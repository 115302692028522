import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '../services/api.service'

import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

export default function AdminReviews() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [deleteId, setDeleteId] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/review`).then((x) => setData(x))
    }, [])

    if (!data) return <Loading></Loading>

    return (
        <>
            <PageHeader headline={'Review Companies'}></PageHeader>

            <div className='flex'>
                <div className='w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl'>
                    <div>
                        <Table
                            data={data.filter(x => x.isSubmitted).map((x) => {
                                return {
                                    ...x,
                                    edit: (
                                        <span className='underline cursor-pointer' onClick={() => navigate(`/admin/review/${x._id}`)}>
                                            Review
                                        </span>
                                    ),
                                }
                            })}
                            columns={[
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Review',
                                    accessor: 'review',
                                },
                                {
                                    Header: 'Edit',
                                    accessor: 'edit',
                                },
                            ]}
                        ></Table>
                    </div>
                </div>
            </div>
        </>
    )
}
