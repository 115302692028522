import { Route, Routes } from 'react-router-dom'

import ErrorBoundary from './components/error-boundary'

import CompanyForm from './forms/company-form'
import UserForm from './forms/user-form'

import AdminCompanies from './admin/admin-companies'
import AdminReview from './admin/admin-review'
import AdminReviews from './admin/admin-reviews'
import AdminUsers from './admin/admin-users'
import AdminLayout from './admin/layout'
import Company from './app/company'
import CompanySearch from './app/company-search'
import DreamerDashboard from './app/dreamer-dashboard'
import DreamerOnboarding from './app/dreamer-onboarding'
import DreamerOnboardingSuccess from './app/dreamer-onboarding-success'
import Home from './app/home'
import InvestorOnboarding from './app/investor-onboarding'
import AppLayout from './app/layout'
import MyDream from './app/my-dream'
import MyInvestments from './app/my-investments'
import Login from './auth/login'
import Logout from './auth/logout'
import Register from './auth/register'
import RequestReset from './auth/request-reset'
import ResetPassword from './auth/reset-password'

export default function App() {
    return (
        <ErrorBoundary componentName='App (top level catch)'>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/request-reset' element={<RequestReset />} />
                <Route path='/passwordReset' element={<ResetPassword />} />

                <Route path='/' element={<AppLayout />}>
                    <Route index element={<Home />} />
                    <Route path='/onboarding' element={<InvestorOnboarding isOnboarding={true} />} />
                    <Route path='/company/:id' element={<Company />} />
                    <Route path='/profile' element={<InvestorOnboarding />} />
                    <Route path='/dashboard' element={<DreamerDashboard />} />
                    <Route path='/search' element={<CompanySearch />} />
                    <Route path='/investments' element={<MyInvestments />} />
                </Route>

                <Route path='/dreamer' element={<AppLayout />}>
                    <Route path='/dreamer/onboarding' element={<DreamerOnboarding isOnboarding={true} />} />
                    <Route path='/dreamer/onboarding/success' element={<DreamerOnboardingSuccess />} />
                    <Route path='/dreamer/my-dream' element={<MyDream />} />
                </Route>

                <Route path='/admin' element={<AdminLayout />}>
                    <Route index element={<AdminUsers />} />
                    <Route path='/admin/manage-user/:id' element={<UserForm />} />

                    <Route path='/admin/companies' element={<AdminCompanies />} />
                    <Route path='/admin/manage-company/:id' element={<CompanyForm />} />

                    <Route path='/admin/review' element={<AdminReviews />} />
                    <Route path='/admin/review/:id' element={<AdminReview />} />
                </Route>
            </Routes>
        </ErrorBoundary>
    )
}
