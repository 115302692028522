import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'

export default function DownloadFile({ name, url, className }) {
    const download = (url, name) => {
        fetch(`${process.env.REACT_APP_API_URL}/document/download-url/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                let extension = url?.split('.').pop()

                const windowUrl = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = windowUrl
                link.setAttribute('download', `${name}.${extension}`)

                document.body.appendChild(link)

                link.click()

                link.parentNode.removeChild(link)
            })
    }

    return (
        <div className={className}>
            <ArrowDownOnSquareIcon onClick={() => download(url, name)} className='w-10 p-2 shadow cursor-pointer bg-neutral-200 hover:bg-neutral-300 rounded-large text-neutral-800'></ArrowDownOnSquareIcon>
        </div>
    )
}
