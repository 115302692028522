import { useEffect, useState } from 'react';
import Select from 'react-select';
import Loading from '../../components/loading';
export default function MultiSelectField({ label, value, onChange, className, inputClass, labelClass, options, optionListName, placeholder, required }) {

    const [selectOptions, setSelectOptions] = useState(options);

    useEffect(() => {
        if (optionListName === "stage") {
            setSelectOptions([{ text: 'Idea', value: 'Idea' },
            { text: 'Prototype', value: 'Prototype' },
            { text: 'MVP', value: 'MVP' },
            { text: 'Revenue generating', value: 'Revenue generating' },
            { text: 'Profitable', value: 'Profitable' },
            { text: 'Scaling', value: 'Scaling' }
            ])
        }
        if (optionListName === "industry") {
            setSelectOptions([{ text: 'Aerospace & Defense', value: 'Aerospace & Defense' },
            { text: 'Agriculture & Forestry', value: 'Agriculture & Forestry' },
            { text: 'Apparel & Fashion', value: 'Apparel & Fashion' },
            { text: 'Architecture & Design', value: 'Architecture & Design' },
            { text: 'Arts, Entertainment & Music', value: 'Arts, Entertainment & Music' },
            { text: 'Automotive & Transport', value: 'Automotive & Transport' },
            { text: 'Banking & Finance', value: 'Banking & Finance' },
            { text: 'Biotechnology & Pharmaceuticals', value: 'Biotechnology & Pharmaceuticals' },
            { text: 'Broadcast & Media Production', value: 'Broadcast & Media Production' },
            { text: 'Chemicals & Petrochemicals', value: 'Chemicals & Petrochemicals' },
            { text: 'Computer & Technology', value: 'Computer & Technology' },
            { text: 'Construction & Civil Engineering', value: 'Construction & Civil Engineering' },
            { text: 'Consumer Goods & Services', value: 'Consumer Goods & Services' },
            { text: 'Digital Marketing & SEO', value: 'Digital Marketing & SEO' },
            { text: 'E-commerce & Internet', value: 'E-commerce & Internet' },
            { text: 'Education & Training', value: 'Education & Training' },
            { text: 'E-learning & Online Courses', value: 'E-learning & Online Courses' },
            { text: 'Electrical & Electronics', value: 'Electrical & Electronics' },
            { text: 'Energy, Oil & Gas', value: 'Energy, Oil & Gas' },
            { text: 'Environmental & Clean Tech', value: 'Environmental & Clean Tech' },
            { text: 'Events & Hospitality', value: 'Events & Hospitality' },
            { text: 'Food & Beverages', value: 'Food & Beverages' },
            { text: 'Furniture & Home Decor', value: 'Furniture & Home Decor' },
            { text: 'Gaming & Interactive Entertainment', value: 'Gaming & Interactive Entertainment' },
            { text: 'Government & Public Services', value: 'Government & Public Services' },
            { text: 'Health, Wellness & Fitness', value: 'Health, Wellness & Fitness' },
            { text: 'Hospital & Healthcare', value: 'Hospital & Healthcare' },
            { text: 'Industrial Manufacturing & Machinery', value: 'Industrial Manufacturing & Machinery' },
            { text: 'Insurance & Risk Management', value: 'Insurance & Risk Management' },
            { text: 'Investment & Financial Services', value: 'Investment & Financial Services' },
            { text: 'IT Services & Consulting', value: 'IT Services & Consulting' },
            { text: 'Law & Legal Services', value: 'Law & Legal Services' },
            { text: 'Logistics, Shipping & Warehousing', value: 'Logistics, Shipping & Warehousing' },
            { text: 'Luxury Goods & Jewelry', value: 'Luxury Goods & Jewelry' },
            { text: 'Marketing, PR & Advertising', value: 'Marketing, PR & Advertising' },
            { text: 'Mining & Metals', value: 'Mining & Metals' },
            { text: 'Non-profit & Philanthropy', value: 'Non-profit & Philanthropy' },
            { text: 'Real Estate & Property Management', value: 'Real Estate & Property Management' },
            { text: 'Renewables & Environment', value: 'Renewables & Environment' },
            { text: 'Research & Development', value: 'Research & Development' },
            { text: 'Retail & Wholesale', value: 'Retail & Wholesale' },
            { text: 'Sports & Recreation', value: 'Sports & Recreation' },
            { text: 'Tech Startups', value: 'Tech Startups' },
            { text: 'Telecommunications', value: 'Telecommunications' },
            { text: 'Textiles & Apparel Manufacturing', value: 'Textiles & Apparel Manufacturing' },
            { text: 'Tourism & Travel', value: 'Tourism & Travel' },
            { text: 'Utilities & Infrastructure', value: 'Utilities & Infrastructure' },
            { text: 'Veterinary & Animal Care', value: 'Veterinary & Animal Care' },
            { text: 'Wine, Spirits & Beverages', value: 'Wine, Spirits & Beverages' },
            { text: 'Writing & Publishing', value: 'Writing & Publishing' },])
        }
    }, [])
    const [query, setQuery] = useState('')

    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))
    if (!selectOptions) return <Loading></Loading>

    return <div className={className}>
        <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
            {label} {required && <span className='text-red-500'>*</span>}
        </label>
        <div className={`relative mt-2 ${inputClass}`}>
            <Select
                value={selectOptions.filter(x => value?.includes(x.value))}
                onChange={(e) => onChange(e.map(x => x.value))}
                isMulti={true}
                getOptionLabel={(x) => x.text}
                getOptionValue={(x) => x.value}
                options={selectOptions}
                classNamePrefix="select"
                placeholder={placeholder}
                className='p-1'
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '0.5rem',
                        padding: '4px'
                    })
                }}
            />
        </div>
    </div>
}

