import { Disclosure } from '@headlessui/react'
import { ChevronLeftIcon, InformationCircleIcon, LinkIcon, MapPinIcon } from '@heroicons/react/20/solid'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import ReactPlayer from 'react-player'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { api } from '../services/api.service'

import Button from '../components/button'
import Card from '../components/card'
import DownloadFile from '../components/download-file'
import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'

import FormModal from '../forms/form-modal'
import InvestForm from '../forms/invest-form'

import { toOxfordComma } from '../utils/strings'

export default function Company({ isDraft, onEdit }) {
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [showInvestModal, setShowInvestModal] = useState(false)
    const [showInvestmentConfirmationModal, setShowInvestmentConfirmationModal] = useState(false)

    const params = useParams()
    const id = params.id

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/company${isDraft ? '' : `/${id}`}`).then((x) => {
            if (x.fundingBreakdown) {
                x.fundingBreakdown = x.fundingBreakdown.filter((portion) => {
                    return portion.name && portion.slice
                })
            }
            if (x.images) {
                x.images = x.images.filter((image) => image !== '')
            }
            if (x.websiteUrl) x.websiteUrl = x.websiteUrl.replace(/^https:\/\//, '')
            if (x.socialMediaLinks) {
                x.socialMediaLinks = x.socialMediaLinks
                    .filter((link) => link.url && link.url !== '')
                    .map((link) => {
                        return link.url.replace(/^https:\/\//, '')
                    })
            }
            setData(x)
        })
    }, [])

    if (!data) return <Loading></Loading>

    return (
        <div className='w-full h-full'>
            <FormModal open={showInvestModal} setOpen={setShowInvestModal}>
                <InvestForm setShowInvestModal={setShowInvestModal} setShowInvestmentConfirmationModal={setShowInvestmentConfirmationModal} companyId={id} />
            </FormModal>

            <FormModal open={showInvestmentConfirmationModal} setOpen={setShowInvestmentConfirmationModal}>
                <Card className='relative flex flex-col p-6 rounded-3xl'>
                    <div className='text-3xl font-medium '>Investment Intent Confirmed!</div>
                    <ConfettiExplosion zIndex={50} particleCount={200} />

                    <br />
                    <div className='text-lg font-medium '>We'll be in touch soon to get your investment set up</div>
                    <br />

                    <Button
                        onClick={() => {
                            navigate('/')
                            setShowInvestmentConfirmationModal(false)
                        }}
                        className='p-10 m-auto font-normal text-white rounded-lg DreamRocket-blue-background'
                    >
                        Keep Searching
                    </Button>
                </Card>
            </FormModal>

            <div className='relative flex flex-col w-full h-full bg-white'>
                <div className='relative w-full h-full overflow-y-scroll no-scrollbar'>
                    {isDraft && <><div className="absolute top-5 right-5">
                        {data.isSubmitted && <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-green-700 rounded-md bg-green-50 ring-1 ring-inset ring-green-600/20">
                            Submitted
                        </span>}
                        {!data.isSubmitted && <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-blue-700 rounded-md bg-blue-50 ring-1 ring-inset ring-blue-600/20">
                            Draft
                        </span>}
                        <Button className="block mt-2" onClick={() => onEdit()}>Edit</Button>

                    </div></>}
                    <div className='absolute block top-5 left-5 sm:hidden'>
                        <ChevronLeftIcon className='w-8 text-white' onClick={() => navigate(-1)} />
                    </div>
                    <div className='flex justify-center w-full py-5 DreamRocket-blue-background sm:bg-transparent rounded-b-3xl'>
                        {data.logo && <ImageViewer className='object-cover w-32 h-32 my-5 bg-white shadow-lg rounded-3xl' small='true' image={data.logo} />}
                    </div>
                    <div className='p-5 text-neutral-700'>
                        <div className="lg:col-start-3 lg:row-end-1">
                            <h2 className="sr-only">Company</h2>
                            <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5">
                                <dl className="flex flex-wrap">
                                    <div className="flex-auto pt-6 pl-6">
                                        <dt className="mt-1 text-3xl font-medium leading-6 text-gray-900">{data.name}</dt>
                                        <dd className="mt-1 text-sm italic font-medium leading-6 text-gray-600">{data.slogan}</dd>
                                    </div>
                                    <div className="flex flex-none w-full px-6 pt-6 mt-6 border-t gap-x-4 border-gray-900/5">
                                        <dt className="flex-none">
                                            <span className="sr-only">Company URL</span>
                                            <LinkIcon className="w-5 h-6 DreamRocket-blue-text" aria-hidden="true" />
                                        </dt>
                                        <dd className="text-sm font-medium leading-6 text-gray-700">{data.websiteUrl}</dd>
                                    </div>
                                    <div className="flex flex-none w-full px-6 mt-4 gap-x-4">
                                        <dt className="flex-none">
                                            <span className="sr-only">Company Location</span>
                                            <MapPinIcon className="w-5 h-6 DreamRocket-blue-text" aria-hidden="true" />
                                        </dt>
                                        <dd className="text-sm font-medium leading-6 text-gray-700">
                                            <time dateTime="2023-01-31">{data.city}, {data.country}</time>
                                        </dd>
                                    </div>
                                    <div className="flex flex-none w-full px-6 pb-6 mt-4 gap-x-4">
                                        <dt className="flex-none">
                                            <span className="sr-only">Company Description</span>
                                            <InformationCircleIcon className="w-5 h-6 DreamRocket-blue-text" aria-hidden="true" />
                                        </dt>
                                        <dd className="text-sm font-medium leading-6 text-gray-700">{data.description}</dd>
                                    </div>
                                </dl>
                                {data.socialMediaLinks?.length > 0 &&
                                    <div className="px-6 py-6 border-t border-gray-900/5">
                                        {data.socialMediaLinks.map((link, index) => {
                                            return (
                                                <Link key={index} onClick={() => window.open(`https://${link}`, '_blank')}>
                                                    <div className='flex justify-between mb-2'>
                                                        <div className='text-sm leading-6 DreamRocket-blue-text font-base'>{link}</div>
                                                        <span className='mr-6 DreamRocket-blue-text' aria-hidden="true">&rarr;</span>
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='grid gap-4 pt-5 sm:grid-cols-2'>
                            {data.images.length > 0 &&
                                data.images.map((image, index) => {
                                    return <ImageViewer key={index} className='rounded-lg shadow' small='true' image={image} />
                                })}
                        </div>

                        <div>
                            <h3 className='mt-10 text-2xl font-semibold leading-6 DreamRocket-blue-text'>Company Details</h3>
                            <dl className="grid grid-cols-2 gap-5 mt-5 sm:grid-cols-3">
                                {data.industry?.length && <div className="col-span-2 px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6 sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500 truncate">Industry</dt>
                                    <dd className="mt-1 text-xl font-semibold tracking-tight text-neutral-700">{data.industry.join(', ')}</dd>
                                </div>}
                                {data.stage && <div className="col-span-2 px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6 sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500 truncate">Stage</dt>
                                    <dd className="mt-1 text-xl font-semibold tracking-tight text-neutral-700">{data.stage}</dd>
                                </div>}
                                {data.targetMarket && <div className="col-span-2 px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6 sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500 truncate">Target Market</dt>
                                    <dd className="mt-1 text-xl font-semibold tracking-tight text-neutral-700">${data.targetMarket}</dd>
                                </div>}
                                {data.fundingSought && <div className="col-span-1 px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
                                    <dt className="text-sm font-medium text-gray-500 truncate">Funding Sought</dt>
                                    <dd className="mt-1 text-xl font-semibold tracking-tight text-neutral-700">${data.fundingSought}</dd>
                                </div>}
                                {data.equityOffered && <div className="col-span-1 px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
                                    <dt className="text-sm font-medium text-gray-500 truncate">Equity Offered</dt>
                                    <dd className="mt-1 text-xl font-semibold tracking-tight text-neutral-700">{data.equityOffered}%</dd>
                                </div>}
                                {data.valuation && <div className="col-span-1 px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
                                    <dt className="text-sm font-medium text-gray-500 truncate">Valuation</dt>
                                    <dd className="mt-1 text-xl font-semibold tracking-tight text-neutral-700">${data.valuation}</dd>
                                </div>}
                                {data.revenue && <div className="col-span-1 px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
                                    <dt className="text-sm font-medium text-gray-500 truncate">Revenue</dt>
                                    <dd className="mt-1 text-xl font-semibold tracking-tight text-neutral-700">${data.revenue}</dd>
                                </div>}
                                <div className="col-span-2 px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6 sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500 truncate">Funding Breakdown</dt>
                                    {data.fundingBreakdown.map((x) => (
                                        <div key={x.name}>
                                            <dd className="mt-1 text-xl font-semibold tracking-tight text-neutral-700">{x.name}: {x.slice}%</dd>
                                        </div>
                                    ))}
                                </div>

                            </dl>
                        </div>

                        <div className="bg-white">
                            <div className="mx-auto mb-10 max-w-7xl">
                                <div className="max-w-4xl mx-auto divide-y divide-gray-900/10">
                                    <h3 className='mt-10 text-2xl font-semibold leading-6 DreamRocket-blue-text'>More Information</h3>
                                    <dl className="px-4 mt-10 space-y-6 divide-y divide-gray-900/10">
                                        {data.founders.length > 0 && <DisclosurePanel title='Founder Information' text={`Founded by ${toOxfordComma(data.founders.map((founder) => founder.name))}`} type='text' />}
                                        {data.missionStatement && <DisclosurePanel title='Mission Statement' text={data.missionStatement} type='text' />}
                                        {data.relevantExperience && <DisclosurePanel title='Relevant Experience' text={data.relevantExperience} type='text' />}
                                        {data.milestones && <DisclosurePanel title='Milestones' text={data.milestones} type='text' />}
                                        {data.businessRegistrationDetails && <DisclosurePanel title='Business Registration Details' text={data.businessRegistrationDetails} type='text' />}
                                        {data.intellectualPropertyList && <DisclosurePanel title='Intellectual Property list' text={data.intellectualPropertyList} type='text' />}
                                        {data.demoVideoUrl && <DisclosurePanel title='Demo Video' text={data.demoVideoUrl} type='video-link' />}
                                    </dl>
                                </div>
                            </div>

                            <div className='grid grid-flow-col gap-6 my-6 auto-cols-fr'>
                                {data.businessModel && <div className='flex flex-col items-center py-6 rounded-lg shadow'>
                                    <div className='mb-4 text-base font-semibold'>Business Model</div>
                                    <DownloadFile url={data.businessModel} />
                                </div>}

                                {data.pitchDeck && <div className="flex flex-col items-center py-6 rounded-lg shadow">
                                    <div className='mb-4 text-base font-semibold'>Pitch deck</div>
                                    <DownloadFile url={data.pitchDeck} />
                                </div>}
                            </div>
                            {
                                data.videoUrl && 
                                    <div>
                                        <h3 className='mt-10 mb-6 text-2xl font-semibold leading-6 DreamRocket-blue-text'>Pitch Video</h3>
                                        {data.videoUrl && <ReactPlayer height='100%' width='100%' controls url={`https://ik.imagekit.io/verticode/dreamrocket/${data.videoUrl.split('/').pop()}`} muted={false} />}
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                {!isDraft && <div className='flex justify-center w-full p-3 bg-white rounded-t-3xl min-h-8 bottom-leaf-box-shadow'>
                    <div onClick={() => setShowInvestModal(true)} className='p-3 px-10 my-3 text-white rounded-full cursor-pointer DreamRocket-blue-background'>
                        INVEST NOW
                    </div>
                </div>}
            </div>
        </div>
    )
}

function DisclosurePanel({ title, text, type }) {
    return (
        <Disclosure as="div" key={title} className="pt-6">
            {({ open }) => (
                <>
                    <dt>
                        <Disclosure.Button className="flex items-start justify-between w-full text-left text-neutral-700">
                            <span className="text-base font-semibold leading-7">{title}</span>
                            <span className="flex items-center ml-6 h-7">
                                {open ? (
                                    <MinusSmallIcon className="w-6 h-6" aria-hidden="true" />
                                ) : (
                                    <PlusSmallIcon className="w-6 h-6" aria-hidden="true" />
                                )}
                            </span>
                        </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="pr-12 mt-2">
                        {type === 'text' && <p className="text-base leading-7 text-gray-600">{text}</p>}
                        {type === 'video-link' && <a className="text-base leading-7 text-blue-600" href={text} target={'_blank'}>{text}</a>}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}