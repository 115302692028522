import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MultiSelectField, SelectField, TextAreaField, TextField } from '../forms/fields'
import ImageField from '../forms/fields/image-upload'
import FormWrapper from '../forms/form-wrapper'

export default function InvestorOnboarding(props) {
    const [missingFields, setMissingFields] = useState([])
    const [stage, setStage] = useState(1)
    const navigate = useNavigate()

    const increaseStage = (e, stage, values) => {
        e.preventDefault()
        setMissingFields([])

        // Check for empty fields
        const missing = runValidation(stage, values)
        if (missing.length > 0) {
            return setMissingFields(missing)
        }

        setStage(stage + 1)
    }
    const decreaseStage = (e) => {
        e.preventDefault()
        setMissingFields([])
        setStage(stage - 1)
    }

    const runValidation = (stage, values) => {
        let errors = []
        if (!props.isOnboarding || stage === 1) {
            errors = []
            if (!values.name) {
                errors.push('Name')
            }
            if (!values.country) {
                errors.push('Country')
            }
            return errors
        } else if (!props.isOnboarding || stage === 2) {
            errors = []
            if (!values.investmentRange) {
                errors.push('Investment Range')
            }
            if (values.preferredIndustries.length === 0) {
                errors.push('Preferred Industries')
            }
            if (values.stagePreferences.length === 0) {
                errors.push('Stage Preferences')
            }
            return errors
        }
        return errors
    }

    const validateThenSubmit = (values, submit) => {
        const missing = runValidation(-1, values)
        if (missing.length > 0) {
            return setMissingFields(missing)
        }
        submit()
    }

    return (
        <>
            <FormWrapper className='flex flex-col flex-1' url='app/user' hideButton={true} noId={true} noStyle={true} callback={() => props.isOnboarding ? navigate('/?fv=y') : navigate('/')}>
                {(values, setValues, submit) => (
                    <div className='flex flex-col flex-1 bg-white'>
                        <div className='flex-1 p-6'>
                            {(!props.isOnboarding || stage === 1) &&
                                <>
                                    <h3 className='my-6 text-2xl font-semibold leading-6 text-blue-500'>Basic Information</h3>
                                    <div className='grid gap-6 px-6 pb-5 sm:grid-cols-2 sm:grid-rows-3'>
                                        <TextField inputClass={missingFields.includes('Name') ? '!border-2 !border-red-500' : 'border-none'} required label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                                        <ImageField popupText="Recommended image size 300x300 pixels" className='sm:col-start-2 sm:row-span-3' label='Profile Picture' value={values.profilePicture} onChange={(val) => setValues({ ...values, profilePicture: val })} />
                                        <SelectField inputClass={missingFields.includes('Country') ? 'border-2 border-red-500' : 'border-none'} required optionListName={'countries'} label='Country' value={values.country} onChange={(val) => setValues({ ...values, country: val })} />
                                        <TextField label='City' value={values.city} onChange={(val) => setValues({ ...values, city: val })}></TextField>
                                    </div>
                                </>
                            }
                            {(!props.isOnboarding || stage === 2) &&
                                <>
                                    <h3 className='my-6 text-2xl font-semibold leading-6 text-blue-500'>Investment Information</h3>
                                    <div className='grid gap-6 px-6 pb-5 sm:grid-cols-2' >
                                        <SelectField inputClass={missingFields.includes('Investment Range') ? 'border-2 border-red-500' : ''} className='sm:col-span-2' required options={[
                                            { text: 'Retail: $10-$10,000', value: 'retail' },
                                            { text: 'Angel: $10,001-$100,000', value: 'angel' },
                                            { text: 'Venture: $100,001 and above', value: 'venture' }
                                        ]} label='Investment Range' value={values.investmentRange} onChange={(val) => setValues({ ...values, investmentRange: val })} />
                                        <MultiSelectField inputClass={missingFields.includes('Preferred Industries') ? 'border-2 border-red-500 rounded-xl' : ''} className='mt-4' required optionListName={'industry'} label='Preferred Industry/Vertical' value={values.preferredIndustries} onChange={(val) => setValues({ ...values, preferredIndustries: val })} />
                                        <MultiSelectField className='mt-4' inputClass={missingFields.includes('Stage Preferences') ? 'border-2 border-red-500 rounded-xl' : ''} required optionListName={'stage'} label='Stage Preference' value={values.stagePreferences} onChange={(val) => setValues({ ...values, stagePreferences: val })} />
                                    </div>
                                </>
                            }
                            {(!props.isOnboarding || stage === 3) &&
                                <>
                                    <h3 className='my-6 text-2xl font-semibold leading-6 text-blue-500'>Extra Information</h3>
                                    <div className='grid gap-6 px-6 pb-5 sm:grid-cols-2'>
                                        <TextAreaField help={<div>Provide a brief overview of your background, experience, and any relevant roles or positions you've held.</div>} label='Bio' value={values.bio} onChange={(val) => setValues({ ...values, bio: val })} />
                                        <TextAreaField help={<div>Share your approach to investing. What do you prioritize or look for in startups? This helps startups understand if they align with your investment criteria.</div>} label='Investment Philosophy' value={values.investmentPhilosophy} onChange={(val) => setValues({ ...values, investmentPhilosophy: val })} />
                                        <TextAreaField help={<div>List startups or companies you've previously invested in that have gained recognition or shown significant growth.</div>} label='Notable Investments' value={values.notableInvestments} onChange={(val) => setValues({ ...values, notableInvestments: val })} />
                                        <TextAreaField help={<div>Highlight any investments that led to successful outcomes, such as acquisitions, IPOs, or substantial returns.</div>} label='Successful Exits' value={values.successfulExits} onChange={(val) => setValues({ ...values, successfulExits: val })} />
                                    </div>
                                </>
                            }
                            {(!props.isOnboarding || stage === 4) &&
                                <>
                                    <h3 className='my-6 text-2xl font-semibold leading-6 text-blue-500'>Visibility</h3>
                                    <div className='grid gap-6 px-6 pb-5 sm:grid-cols-2'>
                                        <TextAreaField help={<div>List any media mentions, interviews, articles, or publications where you've been featured or have contributed. This helps startups understand your influence and reach in the industry.</div>} label='Media & Publications' value={values.mediaPublications} onChange={(val) => setValues({ ...values, mediaPublications: val })} />
                                        <TextAreaField help={<div>Mention any investment groups, associations, or networks you're a part of. This gives startups insight into your connections and affiliations in the investment world.</div>} label='Affiliated Groups' value={values.affiliatedGroups} onChange={(val) => setValues({ ...values, affiliatedGroups: val })} />
                                    </div>
                                </>
                            }

                        </div>
                        {(props.isOnboarding) && <div className='flex flex-col items-center justify-center py-4 shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.25)] align-center rounded-t-2xl'>
                            {missingFields.length > 0 && <div className='mb-4 font-normal'>Please complete all required fields: {missingFields.join(', ')}</div>}
                            <div className='flex items-center justify-center align-center'>
                                {stage !== 1 && <button onClick={decreaseStage} className='inline-flex items-center justify-center w-24 px-4 py-2 mr-6 text-sm font-medium text-white bg-blue-500 border border-transparent shadow-sm cursor-pointer rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>Previous</button>}
                                <button onClick={stage < 5 ? (e) => increaseStage(e, stage, values) : submit()} className='inline-flex items-center justify-center w-24 px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent shadow-sm cursor-pointer rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>Next</button>
                            </div>
                        </div>}
                        {(!props.isOnboarding) && <div className='flex flex-col items-center justify-center py-4 shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.25)] align-center rounded-t-2xl'>
                            {missingFields.length > 0 && <div className='mb-4 font-normal'>Please complete all required fields: {missingFields.join(', ')}</div>}
                            <div className='flex items-center justify-center align-center'>
                                <button onClick={() => validateThenSubmit(values, submit)} className='inline-flex items-center justify-center w-24 px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent shadow-sm cursor-pointer rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>Save</button>
                            </div>
                        </div>}
                    </div>
                )}
            </FormWrapper>
        </>
    )
}
