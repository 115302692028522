import { ArrowUpIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export default function S3Field({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur, type, required }) {

const [loading, setLoading] = useState(false)

    const upload = async (data) => {
        const formData = new FormData();
        formData.append('file', data);

        let result = await fetch(`${process.env.REACT_APP_API_URL}/app/upload`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            body: formData,
        });

        if (result.status === 401) {
            window.location.replace('/login');
        } else {
            let response = await result.json();
            onChange(response.url)
            setLoading(false)
        }
    };
    if (loading) return 'File Uploading'
    return (
        <div className={className + ' mt-2'}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </label>

            {!value && 
                <div className='flex flex-col items-center'>Select a file:
                    <label className="flex flex-col justify-center w-1/2 h-full text-center cursor-pointer bg-neutral-300 rounded-xl">
                        {!loading && <ArrowUpIcon className="w-6 mx-auto mt-4 text-black"></ArrowUpIcon>}
                        {!loading && <p className="py-2 mb-4 text-xs text-black">Click to upload</p>}
                        <input className="absolute w-0 h-0 opacity-0" onChange={(e) => {upload(e.target.files[0]); setLoading(true)}} type="file" name="file" />
                    </label>
                </div>
            }
            {value && 
                <div className="flex flex-col justify-center w-1/2 h-full mx-auto bg-neutral-300 rounded-xl">
                    <CheckCircleIcon className="w-10 mx-auto mt-4 text-black" />
                    <p className="mx-auto my-4 text-center">Successfully uploaded</p>
                </div>
            }

            {value && 
                <label className="flex flex-col items-center justify-center text-center cursor-pointer">
                    <div className="block p-3 mt-4 text-sm font-medium text-white bg-blue-500 rounded w-fit">Replace</div>
                    <input className="absolute w-0 h-0 opacity-0" onChange={(e) => {upload(e.target.files[0]); setLoading(true)}} type="file" name="file" />
                </label>
            }
            


            {/* <div className='mt-1'>
                <input required={required} type={'file'} onBlur={onBlur} placeholder={placeholder} onChange={(e) => upload(e.target.value)} className={`bg-slate-100 block w-full rounded border-0 px-3 p-2 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:text-sm sm:leading-6 ${inputClass}`} />
            </div> */}
        </div>
    )
}
