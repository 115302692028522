import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'


import Loading from '../../components/loading'
import { api } from '../../services/api.service'

export default function SelectField({ label, value, onChange, className, inputClass, labelClass, options, optionListName, placeholder, required }) {
    const [selectOptions, setSelectOptions] = useState(options)
    const [query, setQuery] = useState('')
    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))

    useEffect(() => {
        if (optionListName == 'users') {
            api(`${process.env.REACT_APP_API_URL}/admin/user`).then((x) => setSelectOptions(x.map(x => { return { text: x.email, value: x._id } })))
        }
        if (optionListName == 'companies') {
            api(`${process.env.REACT_APP_API_URL}/admin/company`).then((x) => setSelectOptions(x.map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName == 'countries') {
            setSelectOptions([{ 'text': 'Afghanistan', 'value': 'Afghanistan' },
            { 'text': 'Åland Islands', 'value': 'Åland Islands' },
            { 'text': 'Albania', 'value': 'Albania' },
            { 'text': 'Algeria', 'value': 'Algeria' },
            { 'text': 'American Samoa', 'value': 'American Samoa' },
            { 'text': 'Andorra', 'value': 'Andorra' },
            { 'text': 'Angola', 'value': 'Angola' },
            { 'text': 'Anguilla', 'value': 'Anguilla' },
            { 'text': 'Antarctica', 'value': 'Antarctica' },
            { 'text': 'Antigua & Barbuda', 'value': 'Antigua & Barbuda' },
            { 'text': 'Argentina', 'value': 'Argentina' },
            { 'text': 'Armenia', 'value': 'Armenia' },
            { 'text': 'Aruba', 'value': 'Aruba' },
            { 'text': 'Australia', 'value': 'Australia' },
            { 'text': 'Austria', 'value': 'Austria' },
            { 'text': 'Azerbaijan', 'value': 'Azerbaijan' },
            { 'text': 'Bahamas', 'value': 'Bahamas' },
            { 'text': 'Bahrain', 'value': 'Bahrain' },
            { 'text': 'Bangladesh', 'value': 'Bangladesh' },
            { 'text': 'Barbados', 'value': 'Barbados' },
            { 'text': 'Belarus', 'value': 'Belarus' },
            { 'text': 'Belgium', 'value': 'Belgium' },
            { 'text': 'Belize', 'value': 'Belize' },
            { 'text': 'Benin', 'value': 'Benin' },
            { 'text': 'Bermuda', 'value': 'Bermuda' },
            { 'text': 'Bhutan', 'value': 'Bhutan' },
            { 'text': 'Bolivia', 'value': 'Bolivia' },
            { 'text': 'Bosnia & Herzegovina', 'value': 'Bosnia & Herzegovina' },
            { 'text': 'Botswana', 'value': 'Botswana' },
            { 'text': 'Bouvet Island', 'value': 'Bouvet Island' },
            { 'text': 'Brazil', 'value': 'Brazil' },
            { 'text': 'British Indian Ocean Territory', 'value': 'British Indian Ocean Territory' },
            { 'text': 'British Virgin Islands', 'value': 'British Virgin Islands' },
            { 'text': 'Brunei', 'value': 'Brunei' },
            { 'text': 'Bulgaria', 'value': 'Bulgaria' },
            { 'text': 'Burkina Faso', 'value': 'Burkina Faso' },
            { 'text': 'Burundi', 'value': 'Burundi' },
            { 'text': 'Cambodia', 'value': 'Cambodia' },
            { 'text': 'Cameroon', 'value': 'Cameroon' },
            { 'text': 'Canada', 'value': 'Canada' },
            { 'text': 'Cape Verde', 'value': 'Cape Verde' },
            { 'text': 'Caribbean Netherlands', 'value': 'Caribbean Netherlands' },
            { 'text': 'Cayman Islands', 'value': 'Cayman Islands' },
            { 'text': 'Central African Republic', 'value': 'Central African Republic' },
            { 'text': 'Chad', 'value': 'Chad' },
            { 'text': 'Chile', 'value': 'Chile' },
            { 'text': 'China', 'value': 'China' },
            { 'text': 'Christmas Island', 'value': 'Christmas Island' },
            { 'text': 'Cocos (Keeling) Islands', 'value': 'Cocos (Keeling) Islands' },
            { 'text': 'Colombia', 'value': 'Colombia' },
            { 'text': 'Comoros', 'value': 'Comoros' },
            { 'text': 'Congo - Brazzaville', 'value': 'Congo - Brazzaville' },
            { 'text': 'Congo - Kinshasa', 'value': 'Congo - Kinshasa' },
            { 'text': 'Cook Islands', 'value': 'Cook Islands' },
            { 'text': 'Costa Rica', 'value': 'Costa Rica' },
            { 'text': 'Côte d’Ivoire', 'value': 'Côte d’Ivoire' },
            { 'text': 'Croatia', 'value': 'Croatia' },
            { 'text': 'Cuba', 'value': 'Cuba' },
            { 'text': 'Curaçao', 'value': 'Curaçao' },
            { 'text': 'Cyprus', 'value': 'Cyprus' },
            { 'text': 'Czechia', 'value': 'Czechia' },
            { 'text': 'Denmark', 'value': 'Denmark' },
            { 'text': 'Djibouti', 'value': 'Djibouti' },
            { 'text': 'Dominica', 'value': 'Dominica' },
            { 'text': 'Dominican Republic', 'value': 'Dominican Republic' },
            { 'text': 'Ecuador', 'value': 'Ecuador' },
            { 'text': 'Egypt', 'value': 'Egypt' },
            { 'text': 'El Salvador', 'value': 'El Salvador' },
            { 'text': 'Equatorial Guinea', 'value': 'Equatorial Guinea' },
            { 'text': 'Eritrea', 'value': 'Eritrea' },
            { 'text': 'Estonia', 'value': 'Estonia' },
            { 'text': 'Eswatini', 'value': 'Eswatini' },
            { 'text': 'Ethiopia', 'value': 'Ethiopia' },
            { 'text': 'Falkland Islands', 'value': 'Falkland Islands' },
            { 'text': 'Faroe Islands', 'value': 'Faroe Islands' },
            { 'text': 'Fiji', 'value': 'Fiji' },
            { 'text': 'Finland', 'value': 'Finland' },
            { 'text': 'France', 'value': 'France' },
            { 'text': 'French Guiana', 'value': 'French Guiana' },
            { 'text': 'French Polynesia', 'value': 'French Polynesia' },
            { 'text': 'French Southern Territories', 'value': 'French Southern Territories' },
            { 'text': 'Gabon', 'value': 'Gabon' },
            { 'text': 'Gambia', 'value': 'Gambia' },
            { 'text': 'Georgia', 'value': 'Georgia' },
            { 'text': 'Germany', 'value': 'Germany' },
            { 'text': 'Ghana', 'value': 'Ghana' },
            { 'text': 'Gibraltar', 'value': 'Gibraltar' },
            { 'text': 'Greece', 'value': 'Greece' },
            { 'text': 'Greenland', 'value': 'Greenland' },
            { 'text': 'Grenada', 'value': 'Grenada' },
            { 'text': 'Guadeloupe', 'value': 'Guadeloupe' },
            { 'text': 'Guam', 'value': 'Guam' },
            { 'text': 'Guatemala', 'value': 'Guatemala' },
            { 'text': 'Guernsey', 'value': 'Guernsey' },
            { 'text': 'Guinea', 'value': 'Guinea' },
            { 'text': 'Guinea-Bissau', 'value': 'Guinea-Bissau' },
            { 'text': 'Guyana', 'value': 'Guyana' },
            { 'text': 'Haiti', 'value': 'Haiti' },
            { 'text': 'Heard & McDonald Islands', 'value': 'Heard & McDonald Islands' },
            { 'text': 'Honduras', 'value': 'Honduras' },
            { 'text': 'Hong Kong SAR China', 'value': 'Hong Kong SAR China' },
            { 'text': 'Hungary', 'value': 'Hungary' },
            { 'text': 'Iceland', 'value': 'Iceland' },
            { 'text': 'India', 'value': 'India' },
            { 'text': 'Indonesia', 'value': 'Indonesia' },
            { 'text': 'Iran', 'value': 'Iran' },
            { 'text': 'Iraq', 'value': 'Iraq' },
            { 'text': 'Ireland', 'value': 'Ireland' },
            { 'text': 'Isle of Man', 'value': 'Isle of Man' },
            { 'text': 'Israel', 'value': 'Israel' },
            { 'text': 'Italy', 'value': 'Italy' },
            { 'text': 'Jamaica', 'value': 'Jamaica' },
            { 'text': 'Japan', 'value': 'Japan' },
            { 'text': 'Jersey', 'value': 'Jersey' },
            { 'text': 'Jordan', 'value': 'Jordan' },
            { 'text': 'Kazakhstan', 'value': 'Kazakhstan' },
            { 'text': 'Kenya', 'value': 'Kenya' },
            { 'text': 'Kiribati', 'value': 'Kiribati' },
            { 'text': 'Kuwait', 'value': 'Kuwait' },
            { 'text': 'Kyrgyzstan', 'value': 'Kyrgyzstan' },
            { 'text': 'Laos', 'value': 'Laos' },
            { 'text': 'Latvia', 'value': 'Latvia' },
            { 'text': 'Lebanon', 'value': 'Lebanon' },
            { 'text': 'Lesotho', 'value': 'Lesotho' },
            { 'text': 'Liberia', 'value': 'Liberia' },
            { 'text': 'Libya', 'value': 'Libya' },
            { 'text': 'Liechtenstein', 'value': 'Liechtenstein' },
            { 'text': 'Lithuania', 'value': 'Lithuania' },
            { 'text': 'Luxembourg', 'value': 'Luxembourg' },
            { 'text': 'Macao SAR China', 'value': 'Macao SAR China' },
            { 'text': 'Madagascar', 'value': 'Madagascar' },
            { 'text': 'Malawi', 'value': 'Malawi' },
            { 'text': 'Malaysia', 'value': 'Malaysia' },
            { 'text': 'Maldives', 'value': 'Maldives' },
            { 'text': 'Mali', 'value': 'Mali' },
            { 'text': 'Malta', 'value': 'Malta' },
            { 'text': 'Marshall Islands', 'value': 'Marshall Islands' },
            { 'text': 'Martinique', 'value': 'Martinique' },
            { 'text': 'Mauritania', 'value': 'Mauritania' },
            { 'text': 'Mauritius', 'value': 'Mauritius' },
            { 'text': 'Mayotte', 'value': 'Mayotte' },
            { 'text': 'Mexico', 'value': 'Mexico' },
            { 'text': 'Micronesia', 'value': 'Micronesia' },
            { 'text': 'Moldova', 'value': 'Moldova' },
            { 'text': 'Monaco', 'value': 'Monaco' },
            { 'text': 'Mongolia', 'value': 'Mongolia' },
            { 'text': 'Montenegro', 'value': 'Montenegro' },
            { 'text': 'Montserrat', 'value': 'Montserrat' },
            { 'text': 'Morocco', 'value': 'Morocco' },
            { 'text': 'Mozambique', 'value': 'Mozambique' },
            { 'text': 'Myanmar (Burma)', 'value': 'Myanmar (Burma)' },
            { 'text': 'Namibia', 'value': 'Namibia' },
            { 'text': 'Nauru', 'value': 'Nauru' },
            { 'text': 'Nepal', 'value': 'Nepal' },
            { 'text': 'Netherlands', 'value': 'Netherlands' },
            { 'text': 'New Caledonia', 'value': 'New Caledonia' },
            { 'text': 'New Zealand', 'value': 'New Zealand' },
            { 'text': 'Nicaragua', 'value': 'Nicaragua' },
            { 'text': 'Niger', 'value': 'Niger' },
            { 'text': 'Nigeria', 'value': 'Nigeria' },
            { 'text': 'Niue', 'value': 'Niue' },
            { 'text': 'Norfolk Island', 'value': 'Norfolk Island' },
            { 'text': 'North Korea', 'value': 'North Korea' },
            { 'text': 'North Macedonia', 'value': 'North Macedonia' },
            { 'text': 'Northern Mariana Islands', 'value': 'Northern Mariana Islands' },
            { 'text': 'Norway', 'value': 'Norway' },
            { 'text': 'Oman', 'value': 'Oman' },
            { 'text': 'Pakistan', 'value': 'Pakistan' },
            { 'text': 'Palau', 'value': 'Palau' },
            { 'text': 'Palestinian Territories', 'value': 'Palestinian Territories' },
            { 'text': 'Panama', 'value': 'Panama' },
            { 'text': 'Papua New Guinea', 'value': 'Papua New Guinea' },
            { 'text': 'Paraguay', 'value': 'Paraguay' },
            { 'text': 'Peru', 'value': 'Peru' },
            { 'text': 'Philippines', 'value': 'Philippines' },
            { 'text': 'Pitcairn Islands', 'value': 'Pitcairn Islands' },
            { 'text': 'Poland', 'value': 'Poland' },
            { 'text': 'Portugal', 'value': 'Portugal' },
            { 'text': 'Puerto Rico', 'value': 'Puerto Rico' },
            { 'text': 'Qatar', 'value': 'Qatar' },
            { 'text': 'Réunion', 'value': 'Réunion' },
            { 'text': 'Romania', 'value': 'Romania' },
            { 'text': 'Russia', 'value': 'Russia' },
            { 'text': 'Rwanda', 'value': 'Rwanda' },
            { 'text': 'Samoa', 'value': 'Samoa' },
            { 'text': 'San Marino', 'value': 'San Marino' },
            { 'text': 'São Tomé & Príncipe', 'value': 'São Tomé & Príncipe' },
            { 'text': 'Saudi Arabia', 'value': 'Saudi Arabia' },
            { 'text': 'Senegal', 'value': 'Senegal' },
            { 'text': 'Serbia', 'value': 'Serbia' },
            { 'text': 'Seychelles', 'value': 'Seychelles' },
            { 'text': 'Sierra Leone', 'value': 'Sierra Leone' },
            { 'text': 'Singapore', 'value': 'Singapore' },
            { 'text': 'Sint Maarten', 'value': 'Sint Maarten' },
            { 'text': 'Slovakia', 'value': 'Slovakia' },
            { 'text': 'Slovenia', 'value': 'Slovenia' },
            { 'text': 'Solomon Islands', 'value': 'Solomon Islands' },
            { 'text': 'Somalia', 'value': 'Somalia' },
            { 'text': 'South Africa', 'value': 'South Africa' },
            { 'text': 'South Georgia & South Sandwich Islands', 'value': 'South Georgia & South Sandwich Islands' },
            { 'text': 'South Korea', 'value': 'South Korea' },
            { 'text': 'South Sudan', 'value': 'South Sudan' },
            { 'text': 'Spain', 'value': 'Spain' },
            { 'text': 'Sri Lanka', 'value': 'Sri Lanka' },
            { 'text': 'St. Barthélemy', 'value': 'St. Barthélemy' },
            { 'text': 'St. Helena', 'value': 'St. Helena' },
            { 'text': 'St. Kitts & Nevis', 'value': 'St. Kitts & Nevis' },
            { 'text': 'St. Lucia', 'value': 'St. Lucia' },
            { 'text': 'St. Martin', 'value': 'St. Martin' },
            { 'text': 'St. Pierre & Miquelon', 'value': 'St. Pierre & Miquelon' },
            { 'text': 'St. Vincent & Grenadines', 'value': 'St. Vincent & Grenadines' },
            { 'text': 'Sudan', 'value': 'Sudan' },
            { 'text': 'Suriname', 'value': 'Suriname' },
            { 'text': 'Svalbard & Jan Mayen', 'value': 'Svalbard & Jan Mayen' },
            { 'text': 'Sweden', 'value': 'Sweden' },
            { 'text': 'Switzerland', 'value': 'Switzerland' },
            { 'text': 'Syria', 'value': 'Syria' },
            { 'text': 'Taiwan', 'value': 'Taiwan' },
            { 'text': 'Tajikistan', 'value': 'Tajikistan' },
            { 'text': 'Tanzania', 'value': 'Tanzania' },
            { 'text': 'Thailand', 'value': 'Thailand' },
            { 'text': 'Timor-Leste', 'value': 'Timor-Leste' },
            { 'text': 'Togo', 'value': 'Togo' },
            { 'text': 'Tokelau', 'value': 'Tokelau' },
            { 'text': 'Tonga', 'value': 'Tonga' },
            { 'text': 'Trinidad & Tobago', 'value': 'Trinidad & Tobago' },
            { 'text': 'Tunisia', 'value': 'Tunisia' },
            { 'text': 'Turkey', 'value': 'Turkey' },
            { 'text': 'Turkmenistan', 'value': 'Turkmenistan' },
            { 'text': 'Turks & Caicos Islands', 'value': 'Turks & Caicos Islands' },
            { 'text': 'Tuvalu', 'value': 'Tuvalu' },
            { 'text': 'U.S. Outlying Islands', 'value': 'U.S. Outlying Islands' },
            { 'text': 'U.S. Virgin Islands', 'value': 'U.S. Virgin Islands' },
            { 'text': 'Uganda', 'value': 'Uganda' },
            { 'text': 'Ukraine', 'value': 'Ukraine' },
            { 'text': 'United Arab Emirates', 'value': 'United Arab Emirates' },
            { 'text': 'United Kingdom', 'value': 'United Kingdom' },
            { 'text': 'United States', 'value': 'United States' },
            { 'text': 'Uruguay', 'value': 'Uruguay' },
            { 'text': 'Uzbekistan', 'value': 'Uzbekistan' },
            { 'text': 'Vanuatu', 'value': 'Vanuatu' },
            { 'text': 'Vatican City', 'value': 'Vatican City' },
            { 'text': 'Venezuela', 'value': 'Venezuela' },
            { 'text': 'Vietnam', 'value': 'Vietnam' },
            { 'text': 'Wallis & Futuna', 'value': 'Wallis & Futuna' },
            { 'text': 'Western Sahara', 'value': 'Western Sahara' },
            { 'text': 'Yemen', 'value': 'Yemen' },
            { 'text': 'Zambia', 'value': 'Zambia' },
            { 'text': 'Zimbabwe', 'value': 'Zimbabwe' },])
        }
        if (optionListName === "stage") {
            setSelectOptions([{ text: 'Idea', value: 'Idea' },
            { text: 'Prototype', value: 'Prototype' },
            { text: 'MVP', value: 'MVP' },
            { text: 'Revenue generating', value: 'Revenue generating' },
            { text: 'Profitable', value: 'Profitable' },
            { text: 'Scaling', value: 'Scaling' }
            ])
        }
        if (optionListName === "industry") {
            setSelectOptions([{ text: 'Aerospace & Defense', value: 'Aerospace & Defense' },
            { text: 'Agriculture & Forestry', value: 'Agriculture & Forestry' },
            { text: 'Apparel & Fashion', value: 'Apparel & Fashion' },
            { text: 'Architecture & Design', value: 'Architecture & Design' },
            { text: 'Arts, Entertainment & Music', value: 'Arts, Entertainment & Music' },
            { text: 'Automotive & Transport', value: 'Automotive & Transport' },
            { text: 'Banking & Finance', value: 'Banking & Finance' },
            { text: 'Biotechnology & Pharmaceuticals', value: 'Biotechnology & Pharmaceuticals' },
            { text: 'Broadcast & Media Production', value: 'Broadcast & Media Production' },
            { text: 'Chemicals & Petrochemicals', value: 'Chemicals & Petrochemicals' },
            { text: 'Computer & Technology', value: 'Computer & Technology' },
            { text: 'Construction & Civil Engineering', value: 'Construction & Civil Engineering' },
            { text: 'Consumer Goods & Services', value: 'Consumer Goods & Services' },
            { text: 'Digital Marketing & SEO', value: 'Digital Marketing & SEO' },
            { text: 'E-commerce & Internet', value: 'E-commerce & Internet' },
            { text: 'Education & Training', value: 'Education & Training' },
            { text: 'E-learning & Online Courses', value: 'E-learning & Online Courses' },
            { text: 'Electrical & Electronics', value: 'Electrical & Electronics' },
            { text: 'Energy, Oil & Gas', value: 'Energy, Oil & Gas' },
            { text: 'Environmental & Clean Tech', value: 'Environmental & Clean Tech' },
            { text: 'Events & Hospitality', value: 'Events & Hospitality' },
            { text: 'Food & Beverages', value: 'Food & Beverages' },
            { text: 'Furniture & Home Decor', value: 'Furniture & Home Decor' },
            { text: 'Gaming & Interactive Entertainment', value: 'Gaming & Interactive Entertainment' },
            { text: 'Government & Public Services', value: 'Government & Public Services' },
            { text: 'Health, Wellness & Fitness', value: 'Health, Wellness & Fitness' },
            { text: 'Hospital & Healthcare', value: 'Hospital & Healthcare' },
            { text: 'Industrial Manufacturing & Machinery', value: 'Industrial Manufacturing & Machinery' },
            { text: 'Insurance & Risk Management', value: 'Insurance & Risk Management' },
            { text: 'Investment & Financial Services', value: 'Investment & Financial Services' },
            { text: 'IT Services & Consulting', value: 'IT Services & Consulting' },
            { text: 'Law & Legal Services', value: 'Law & Legal Services' },
            { text: 'Logistics, Shipping & Warehousing', value: 'Logistics, Shipping & Warehousing' },
            { text: 'Luxury Goods & Jewelry', value: 'Luxury Goods & Jewelry' },
            { text: 'Marketing, PR & Advertising', value: 'Marketing, PR & Advertising' },
            { text: 'Mining & Metals', value: 'Mining & Metals' },
            { text: 'Non-profit & Philanthropy', value: 'Non-profit & Philanthropy' },
            { text: 'Real Estate & Property Management', value: 'Real Estate & Property Management' },
            { text: 'Renewables & Environment', value: 'Renewables & Environment' },
            { text: 'Research & Development', value: 'Research & Development' },
            { text: 'Retail & Wholesale', value: 'Retail & Wholesale' },
            { text: 'Sports & Recreation', value: 'Sports & Recreation' },
            { text: 'Tech Startups', value: 'Tech Startups' },
            { text: 'Telecommunications', value: 'Telecommunications' },
            { text: 'Textiles & Apparel Manufacturing', value: 'Textiles & Apparel Manufacturing' },
            { text: 'Tourism & Travel', value: 'Tourism & Travel' },
            { text: 'Utilities & Infrastructure', value: 'Utilities & Infrastructure' },
            { text: 'Veterinary & Animal Care', value: 'Veterinary & Animal Care' },
            { text: 'Wine, Spirits & Beverages', value: 'Wine, Spirits & Beverages' },
            { text: 'Writing & Publishing', value: 'Writing & Publishing' },])
        }
        if (optionListName === "registration") {
            setSelectOptions([
                { text: "Company", value: "Company" },
                { text: "Investor", value: "Investor" },
            ])
        }
    }, [optionListName])

    if (!selectOptions) return <Loading></Loading>

    return (
        <div className={className + ' mt-2'}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </label>
            <div className='relative mt-1 rounded shadow-sm '>
                <Combobox
                    value={selectOptions.find((x) => x.value === value)}
                    onChange={(e) => {
                        onChange(e)
                    }}
                >
                    <div className='relative mt-1'>
                        <div className='relative w-full overflow-hidden text-left rounded-lg cursor-default sm:text-sm'>
                            <Combobox.Input placeholder={placeholder} className={`border-none w-full py-3 pl-3 pr-10 text-base leading-6 rounded-lg text-neutral-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-400 ${inputClass}`} onChange={(e) => setQuery(e.target.value)} displayValue={(x) => x?.text} required={required} />
                            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                                <ChevronUpDownIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                            </Combobox.Button>
                        </div>
                        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0' afterLeave={() => setQuery('')}>
                            <Combobox.Options className='absolute z-40 w-full py-1 mt-1 overflow-auto text-base bg-white rounded shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 sm:text-sm'>
                                {filtered.map((x) => (
                                    <Combobox.Option key={x.value} value={x.value} className={({ active }) => `relative cursor-default select-none py-2 px-3  ${x.value === value ? 'bg-blue-600 text-white' : `${x.value !== value && active ? 'bg-blue-400 text-white' : 'text-neutral-700'}`} `}>
                                        {x.text}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </Combobox>
            </div>
        </div>
    )
}
