import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { api } from '../services/api.service'

export default function MyInvestments() {
    const [data, setData] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/my-investments`).then((x) => {
            setData(x)
        })
    }, [])

    return (
        <div className='w-full max-w-2xl p-10 mx-auto overflow-scroll bg-white'>
            <h3 className='my-2 text-2xl font-semibold leading-6 DreamRocket-blue-text'>My Investments</h3>
            {data?.length > 0 && <ul role="list" className="divide-y divide-gray-100">
                {data && data.map((inv) => (
                    <li key={inv._id} className="flex items-center justify-between py-5 gap-x-6">
                        <div className="min-w-0">
                            <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{inv.company?.name}</p>
                                <p className='text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'>
                                    Interested
                                </p>
                            </div>
                            <div className="flex items-center mt-1 text-xs leading-5 text-gray-500 gap-x-2">
                                <p className="whitespace-nowrap">
                                    ${inv.amount}
                                </p>
                                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                    <circle cx={1} cy={1} r={1} />
                                </svg>
                                <p className="truncate">Sent: {moment(inv.createdAt).format('MM-DD-YYYY')}</p>
                            </div>
                        </div>
                        <div className="flex items-center flex-none gap-x-4">
                            <a
                            href={`/company/${inv.company?._id}`}
                            className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                            >
                            View Company<span className="sr-only">, {inv.company?.name}</span>
                            </a>
                        </div>
                    </li>
                ))}
            </ul>}
            {data?.length === 0 && 
                <div className='flex justify-center w-full align-center'>
                    You have not made any investment requests yet.
                </div>
            }
        </div>
    )
}