import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { api } from '../services/api.service'

import Loading from '../components/loading'
import PageHeader from '../components/page-header'

import CompanyReviewForm from '../forms/company-review-form'

export default function AdminReview() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)

    const params = useParams()
    const id = params.id

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/review/${id}`).then((x) => setData(x))
    }, [])

    const setApprovalStatus = (approved, rejectionComments) => {
        api(`${process.env.REACT_APP_API_URL}/admin/set-approval-status/${id}/${approved}`, { rejectionComments }).then((x) => navigate(-1))
    }

    if (!data) return <Loading></Loading>

    return (
        <>
            <PageHeader headline={'Review Company'}></PageHeader>
            <CompanyReviewForm companyData={data} setApprovalStatus={(approved, rejectionComments) => setApprovalStatus(approved, rejectionComments)} />

            <div className='flex'>{/* Todo - Display fields and add review buttons etc */}</div>
        </>
    )
}
