import { CheckIcon } from '@heroicons/react/20/solid'
import { ArrowDownOnSquareIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { api, api_file } from '../../services/api.service'

import Button from '../../components/button'

import HelpPopup from '../../components/help-popup'
import FormModal from '../form-modal'

export default function FileField({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur, endpoint, help }) {
    const [open, _setOpen] = useState(false)

    const [values, setValues] = useState(value ?? {})

    const setOpen = (val) => {
        _setOpen(val)
        if (val) {
            if (value) api(`${process.env.REACT_APP_API_URL}/document/${value}`).then((x) => setValues(x))
        }
    }

    const uploadFile = (file) => {
        const formData = new FormData()
        formData.append('file', file)
        api_file(`${process.env.REACT_APP_API_URL}/document/file`, formData).then((x) => setValues({ ...values, url: x.name }))
    }

    const download = (url) => {
        fetch(`${process.env.REACT_APP_API_URL}/document/download-url/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                let extension = values.url?.split('.').pop()
                let name = values.url?.split('.')[0]

                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${name}.${extension}`)

                document.body.appendChild(link)

                link.click()

                link.parentNode.removeChild(link)
            })
    }

    const save = () => {
        api(`${process.env.REACT_APP_API_URL}/document`, { data: values }).then((x) => {
            onChange(x.id)
            setOpen(false)
        })
    }

    return (
        <>
            <FormModal open={open} setOpen={setOpen}>
                <div className="p-5 bg-white rounded shadow">

                    <div className={`block leading-6 text-neutral-700 text-2xl font-semibold mb-5 relative ${labelClass}`}>{label} {help && <HelpPopup>{help}</HelpPopup>}</div>

                    {values.url && (<div className='flex flex-col items-center'>
                        <div className='flex-1 block text-xs font-medium text-left text-zinc-700'>

                            <span>
                                <b>Uploaded File:</b> {values.url}
                            </span>

                        </div>
                        <div className='shrink-0'>
                            <ArrowDownOnSquareIcon onClick={() => download(values.url, values.name)} className='w-10 p-2 shadow cursor-pointer bg-neutral-200 hover:bg-neutral-300 rounded-large text-neutral-800'></ArrowDownOnSquareIcon>
                        </div>
                    </div>)}

                    <label>
                        <input onChange={(e) => uploadFile(e.target.files[0])} type='file' name='file' className='w-0 h-0 opacity-0' />
                        <div className='p-3 text-sm font-medium text-center cursor-pointer bg-gradient-to-b from-neutral-50 to-neutral-100 text-zinc-700 hover:text-zinc-900'>
                            {values.url && <span>Replace</span>}
                            {!values.url && <span>Upload</span>} File
                        </div>
                    </label>

                    <Button className='mt-5' onClick={() => save()}>
                        Save
                    </Button>

                </div>

            </FormModal>

            <div className={className}>
                <div className={`block text-sm font-medium leading-6 text-neutral-600 relative ${labelClass}`}>{label} {help && <HelpPopup>{help}</HelpPopup>}</div>
                <div onClick={() => setOpen(true)} className='w-10 h-10 p-2 mx-auto rounded-full shadow cursor-pointer hover:bg-neutral-300 bg-neutral-200 text-neutral-800'>
                    {value && <CheckIcon className='text-blue-500'></CheckIcon>}
                    {!value && <ArrowUpOnSquareIcon></ArrowUpOnSquareIcon>}
                </div>
            </div>
        </>
    )
}
