import { useState } from 'react'


import Company from './company'
import DreamerOnboarding from './dreamer-onboarding'



export default function MyDream() {
    const [isEditing, setIsEditing] = useState(false)

    return (
        <div className='w-full h-full'>
            {isEditing && <DreamerOnboarding onSubmit={() => setIsEditing(false)} />}
            {!isEditing && <Company isDraft={true} onEdit={() => setIsEditing(true)} />}
        </div>
    )
}