import React from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import { useNavigate } from 'react-router'

export default function DreamerOnboardingSuccess() {
    const navigate = useNavigate()
    return (
        <div className='flex flex-col flex-1'>
            <div className='flex flex-col items-center flex-1 bg-white'>
                <h2 className='text-2xl font-semibold mt-28'>Dream Submitted!</h2>
                <ConfettiExplosion force={0.8} duration={3000} particleCount={200}/>
                <div className='my-12 font-light text-center'>
                    We'll be in touch as soon as your profile is being shown to investors!
                </div>
            </div>
            <div className='bg-white flex flex-col items-center justify-center py-4 shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.25)] align-center rounded-t-2xl'>
                <div className='flex items-center justify-center align-center'>
                    <button type='button' onClick={() => navigate('/dashboard')} className='inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium text-white bg-blue-500 border border-transparent rounded shadow-sm cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>Go To Dashboard</button>
                </div>
            </div>
        </div>
        
    )
}
