import { useEffect, useState } from 'react'

import { api } from '../services/api.service.js'

import Loading from '../components/loading'

export default function FormWrapper({ url, id, callback, children, includeIdInPost, buttonText, hideButton, noStyle, defaultValue, noId, noLoad, validate, className }) {
    const [values, setValues] = useState()

    useEffect(() => {
        if (noId) {
            if (noLoad) {
                setValues(defaultValue ?? {})
            } else {
                api(`${process.env.REACT_APP_API_URL}/${url}`).then((x) => {
                    setValues({ ...defaultValue, ...x })
                })
            }
        } else {
            if (id !== 'new')
                api(`${process.env.REACT_APP_API_URL}/${url}/${id}`).then((x) => {
                    setValues(x ?? {})
                })
            else {
                setValues(defaultValue ?? {})
            }
        }
    }, [id, url])

    function submit(data) {
        if (validate && !validate(values)) return

        if (includeIdInPost && !noId) {
            api(`${process.env.REACT_APP_API_URL}/${url}/${id}`, { data: data ?? values }).then((x) => {
                callback()
            })
        } else {
            api(`${process.env.REACT_APP_API_URL}/${url}`, { data: data ?? values }).then((x) => {
                callback()
            })
        }
    }

    if (!values) return <Loading></Loading>

    return (
        <div className={noStyle ? className ?? '' : 'h-fit 2xl:max-w-6xl max-w-5xl mx-auto my-5 bg-white shadow rounded p-5'}>
            <form
                className={className}
                onSubmit={(e) => {
                    e.preventDefault()
                    submit()
                }}
            >
                {children(values, setValues, submit)}
                <div className='flex items-center justify-center align-center'>{!hideButton && <button className='inline-flex items-center justify-center w-24 px-4 py-2 mt-5 text-sm font-medium text-white bg-blue-500 border border-transparent rounded shadow-sm cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>{buttonText ?? 'Submit'}</button>}</div>
            </form>
        </div>
    )
}
