import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import HelpPopup from '../components/help-popup'

import { CurrencyField, FileField, MultiSelectField, NumberField, SelectField, TextAreaField, TextField } from '../forms/fields'
import ImageField from '../forms/fields/image-upload'
import S3Field from '../forms/fields/s3-field'
import FormWrapper from '../forms/form-wrapper'
import { api } from '../services/api.service'

export default function DreamerOnboarding(props) {
    const [missingFields, setMissingFields] = useState([])
    const [stage, setStage] = useState(1)
    const params = useParams()
    const navigate = useNavigate()
    const id = params.id

    const increaseStage = (e, stage, values) => {
        e.preventDefault()
        setMissingFields([])

        // Check for empty fields
        const missing = runValidation(e, stage, values)
        if (missing.length > 0) {
            return setMissingFields(missing)
        }

        setStage(stage + 1)
    }
    const decreaseStage = (e) => {
        e.preventDefault()
        setMissingFields([])
        setStage(stage - 1)
    }
    const runValidation = (e, stage, values) => {
        let errors = []

        if (stage === 1) {
            errors = []
            if (!values.name) {
                errors.push('Name')
            }
            if (!values.country) {
                errors.push('Country')
            }
            return errors
        } else if (stage === 3) {
            errors = []
            if (!values.stage) {
                errors.push('Stage')
            }
            if (!values.industry?.length > 0) {
                errors.push('Industry')
            }
            return errors
        } else if (stage === 4) {
            errors = []
            if (!values.fundingSought) {
                errors.push('Funding Sought')
            }
            return errors
        } else if (stage === 7) {
            e.preventDefault()
            values = []
            if (!values.videoUrl) {
                errors.push('Video')
            }
            return errors
        }
        return errors
    }

    const validateThenSave = (values, submit) => {
        const missing = runValidation(-1, values)
        if (missing.length > 0) {
            return setMissingFields(missing)
        }
        submit()
    }

    const validateThenSubmit = (values) => {
        const missing = runValidation(-1, values)
        if (missing.length > 0) {
            return setMissingFields(missing)
        }
        api(`${process.env.REACT_APP_API_URL}/app/submit-changes`, { data: values }).then((x) => {
            isOnboarding ? navigate('/dreamer/onboarding/success') : props.onSubmit()
        })
    }

    const missingVideo = (e) => {
        e.preventDefault()
        return setMissingFields(['Video'])
    }

    let isOnboarding = props.isOnboarding;

    return (
        <>
            <FormWrapper className='flex flex-col flex-1' noId={true} noStyle={true} url='app/company' hideButton={true} defaultValue={{ founders: [{}], socialMediaLinks: [{}], fundingBreakdown: [{}], images: [""] }} id={id} {...props} callback={() => isOnboarding ? navigate('/dreamer/onboarding/success') : props.onSubmit()}>
                {(values, setValues, submit) => (
                    <div className='flex flex-col flex-1 bg-white'>
                        <div className='flex-1 p-6'>
                            {(!isOnboarding || stage === 1) && (
                                <>
                                    <h3 className='mt-2 text-2xl font-semibold leading-6 text-blue-500'>Basic Information</h3>
                                    <div className='grid gap-6 sm:grid-cols-2'>
                                        <TextField inputClass={missingFields.includes('Name') ? '!border-2 !border-red-500' : 'border-none'} required label='Business Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                                        <ImageField popupText="Recommended image size 300x300 pixels" className='sm:col-start-2 sm:row-span-3' label='Logo' value={values.logo} onChange={(val) => setValues({ ...values, logo: val })} />
                                        <SelectField inputClass={missingFields.includes('Country') ? 'ring-2 ring-red-500' : ''} required optionListName={'countries'} label='Country' value={values.country} onChange={(val) => setValues({ ...values, country: val })} />
                                        <TextField label='City' value={values.city} onChange={(val) => setValues({ ...values, city: val })}></TextField>
                                    </div>
                                </>
                            )}

                            {(!isOnboarding || stage === 2) && (
                                <>
                                    <h3 className='mt-10 text-2xl font-semibold leading-6 text-blue-500'>Founders</h3>
                                    <div className='grid gap-4 sm:grid-cols-2 sm:auto-rows-fr'>
                                        {values.founders.map((x, i) => (
                                            <div className='flex items-center space-x-4'>
                                                <TextField className='flex-1' label={`Founder ${i + 1} name`} value={x.name} onChange={(val) => setValues({ ...values, founders: values.founders.map((y, j) => (j == i ? { ...y, name: val } : y)) })}></TextField>
                                                <div className='mt-8 text-xl font-bold cursor-pointer' onClick={() => setValues({ ...values, founders: values.founders.filter((y, j) => j !== i) })}>
                                                    X
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='mb-6 w-fit' onClick={() => setValues({ ...values, founders: values.founders.concat([{}]) })}>
                                        <span className='cursor-pointer'>+ Add Founder</span>
                                    </div>

                                    <h3 className='mt-10 text-2xl font-semibold leading-6 text-blue-500'>Web</h3>
                                    <TextField label='Website URL' value={values.websiteUrl} onChange={(val) => setValues({ ...values, websiteUrl: val })}></TextField>

                                    <h3 className='mt-10 text-2xl font-semibold leading-6 text-blue-500'>Social</h3>
                                    {values.socialMediaLinks.map((x, i) => (
                                        <div className='flex items-center space-x-4'>
                                            <TextField className='flex-1' label={`Link ${i + 1}`} value={x.url} onChange={(val) => setValues({ ...values, socialMediaLinks: values.socialMediaLinks.map((y, j) => (j == i ? { ...y, url: val } : y)) })}></TextField>
                                            <div className='mt-8 text-xl font-bold cursor-pointer' onClick={() => setValues({ ...values, socialMediaLinks: values.socialMediaLinks.filter((y, j) => j !== i) })}>
                                                X
                                            </div>
                                        </div>
                                    ))}
                                    <div className='w-fit' onClick={() => setValues({ ...values, socialMediaLinks: values.socialMediaLinks.concat([{}]) })}>
                                        <span className='cursor-pointer'>+ Add Link</span>
                                    </div>
                                </>
                            )}

                            {(!isOnboarding || stage === 3) && (
                                <>
                                    <h3 className='mt-10 text-2xl font-semibold leading-6 text-blue-500'> Company Information</h3>
                                    <div className='grid gap-6 sm:grid-cols-2'>
                                        <SelectField inputClass={missingFields.includes('Stage') ? 'ring-2 ring-red-500' : ''} required optionListName={'stage'} label='Stage' value={values.stage} onChange={(val) => setValues({ ...values, stage: val })} />
                                        <MultiSelectField inputClass={missingFields.includes('Industry') ? 'ring-2 rounded-lg ring-red-500' : ''} required optionListName={'industry'} label='Industry/Vertical' value={values.industry} onChange={(val) => setValues({ ...values, industry: val })} />
                                        <TextAreaField help={<div>
                                            <p>Your target market is the specific group of people or businesses you aim to serve with your product or service. Consider the following when defining your target market:</p>
                                            <p className="mt-5"><b>Demographics:</b> Age, gender, income level, education, occupation, etc.</p>
                                            <p><b>Geographics:</b> Specific regions, countries, cities, or neighborhoods.</p>
                                            <p><b>Psychographics:</b> Interests, hobbies, values, habits, etc.</p>
                                            <p><b>Behavioral Traits:</b> Purchasing habits, brand loyalty, product usage rate, etc.</p>
                                            <p className="mt-5"><b>Tip:</b> Be as specific as possible. Instead of saying "everyone," narrow it down. For instance, "Women aged 25-34, living in urban areas, interested in sustainable fashion."</p>
                                        </div>} label='Target Market' value={values.bio} onChange={(val) => setValues({ ...values, bio: val })}
                                        />
                                        <TextAreaField maxLength={80} required placeholder="Be creative and concise, this is your first impression!" label='Slogan' value={values.slogan} onChange={(val) => setValues({ ...values, slogan: val })} />
                                        <TextAreaField placeholder="Provide a brief overview of your business." className='sm:col-span-2' label='Company Description' value={values.description} onChange={(val) => setValues({ ...values, description: val })} />
                                    </div>
                                </>
                            )}

                            {(!isOnboarding || stage === 4) && (
                                <>
                                    <h3 className='mt-10 text-2xl font-semibold leading-6 text-blue-500'>Financial Information</h3>
                                    <div className='grid sm:gap-6 sm:grid-cols-2'>
                                        <div className='sm:row-span-3'>
                                            <CurrencyField inputClass={missingFields.includes('Funding Sought') ? 'ring-2 ring-red-500' : 'border-none'} required label='Funding Sought' value={values.fundingSought} onChange={(val) => setValues({ ...values, fundingSought: val })} />
                                            <div className='relative mt-6 font-semibold'>Funding Breakdown Slices
                                                <HelpPopup>
                                                    <div>
                                                        <p>This section allows you to allocate the funds you aim to raise among various business processes. It provides potential investors with a clear understanding of how you intend to use their investment. </p>
                                                        <p className="mt-5">Consider the following:</p>
                                                        <p className="mt-5"><b>Prototyping:</b> Costs associated with creating a preliminary version of your product.</p>
                                                        <p><b>Marketing:</b> Expenses for promoting your product or service, including advertising, PR, and social media campaigns.</p>
                                                        <p><b>Operations:</b> Day-to-day business expenses, such as rent, utilities, and salaries.</p>
                                                        <p><b>Research & Development:</b> Costs for improving existing products or developing new ones.</p>
                                                        <p><b>Inventory:</b> Funds allocated for stocking up on products or raw materials.</p>
                                                        <p><b>Others:</b> Any other significant expenses specific to your business.</p>
                                                        <p className="mt-5"><b>Tip:</b> Ensure your percentages add up to 100%. Be realistic and prioritize areas that will drive growth and return on investment</p>
                                                    </div>
                                                </HelpPopup>
                                            </div>
                                            {values.fundingBreakdown.map((x, i) => (
                                                <div className='flex items-center mt-4 space-x-4'>
                                                    <TextField className='flex-1' label={`Slice ${i + 1} Name`} value={x.name} onChange={(val) => setValues({ ...values, fundingBreakdown: values.fundingBreakdown.map((y, j) => (j == i ? { ...y, name: val } : y)) })}></TextField>
                                                    <TextField className='flex-1' label={`Slice ${i + 1} Size (%)`} value={x.slice} onChange={(val) => setValues({ ...values, fundingBreakdown: values.fundingBreakdown.map((y, j) => (j == i ? { ...y, slice: val } : y)) })}></TextField>
                                                    <div className='mt-8 text-xl font-bold cursor-pointer' onClick={() => setValues({ ...values, fundingBreakdown: values.fundingBreakdown.filter((y, j) => j !== i) })}>X</div>
                                                </div>
                                            ))}
                                            <div className='mb-6 w-fit' onClick={() => setValues({ ...values, fundingBreakdown: values.fundingBreakdown.concat([{}]) })}><span className='cursor-pointer'>+ Add Breakdown Item</span></div>
                                        </div>
                                        <NumberField label='% Equity Offered' value={values.equityOffered} onChange={(val) => setValues({ ...values, equityOffered: val })} />
                                        <CurrencyField label='Valuation' value={values.valuation} onChange={(val) => setValues({ ...values, valuation: val })} />
                                        <CurrencyField label='Revenue' value={values.revenue} onChange={(val) => setValues({ ...values, revenue: val })} />
                                    </div>
                                </>
                            )}

                            {(!isOnboarding || stage === 5) && (
                                <>
                                    <h3 className='mt-10 text-2xl font-semibold leading-6 text-blue-500'>Company Story</h3>
                                    <div className='grid grid-cols-1 row-auto gap-6 sm:grid-cols-2'>
                                        <TextAreaField placeholder="Concisely describe your company's core purpose and impact." label='Mission Statement' value={values.missionStatement} onChange={(val) => setValues({ ...values, missionStatement: val })} />
                                        <TextAreaField placeholder="Share your past roles, achievements, or projects that showcase your expertise related to this venture." label='Relevant Experience' value={values.relevantExperience} onChange={(val) => setValues({ ...values, relevantExperience: val })} />
                                        <TextAreaField help={<div>
                                            <p>Milestones are significant achievements or stages in the development of your venture. They help potential investors understand your progress and future plans. </p>
                                            <p className="mt-5">Examples include product launches, user acquisition targets, or key partnerships.</p>
                                        </div>} label='Milestones' value={values.milestones} onChange={(val) => setValues({ ...values, milestones: val })}
                                        />
                                        <TextField label='Video Demo URL' value={values.demoVideoUrl} onChange={(val) => setValues({ ...values, demoVideoUrl: val })}></TextField>
                                    </div>
                                    <div className='relative mt-6 font-semibold'>Upload Product Images <HelpPopup text="Recommended image size 300x300 pixels" /></div>
                                    <div className='grid grid-cols-1 row-auto gap-2 sm:grid-cols-2 lg:grid-cols-4'>
                                        {values.images?.map((x, i) => <div className="flex items-center space-x-4">
                                            <ImageField className="flex-1" label={`Image ${i + 1}`} value={x} onChange={(val) => setValues({ ...values, images: values.images.map((y, j) => j == i ? val : y) })} />
                                            <div className="mt-8 text-xl font-medium text-gray-600 cursor-pointer" onClick={() => setValues({ ...values, images: values.images.filter((y, j) => j !== i) })}>X</div>
                                        </div>
                                        )}
                                    </div>
                                    <div className='mt-2 text-sm cursor-pointer w-fit' onClick={() => setValues({ ...values, images: values.images.concat([""]) })}>+ Add Image</div>
                                </>
                            )}

                            {(!isOnboarding || stage === 6) && (
                                <>
                                    <h3 className='mt-10 text-2xl font-semibold leading-6 text-blue-500'>Company Admin</h3>
                                    <div className='grid grid-cols-2 gap-6 justify-items-center'>
                                        <TextAreaField
                                            className='w-full col-span-2 sm:col-span-1'
                                            help={<div><p>Specify the legal structure and jurisdiction of your business. This helps potential investors understand your company's legal standing and obligations. Common structures include LLC, Corporation, Sole Proprietorship, etc.</p><p className="mt-5">Examples include 'LLC registered in Delaware', 'Sole Proprietorship in California'.</p></div>}
                                            label='Business Registration Details'
                                            value={values.businessRegistrationDetails}
                                            onChange={(val) => setValues({ ...values, businessRegistrationDetails: val })}
                                        />
                                        <TextAreaField
                                            className='w-full col-span-2 sm:col-span-1'
                                            placeholder="List any patents, trademarks, copyrights, or other intellectual property your business holds or has applied for."
                                            label='Intellectual Property List'
                                            value={values.intellectualPropertyList}
                                            onChange={(val) => setValues({ ...values, intellectualPropertyList: val })}
                                        />
                                        <FileField
                                            help={<div>Upload a clear outline of how your company plans to make money. Consider using a 'Business Model Canvas' for a concise representation. It should highlight your value proposition, competitors, customers, and revenue sources. Formats accepted: PDF, PNG, JPEG.</div>}
                                            className='flex flex-col items-start w-40 py-6 pl-3 mx-10 mt-6 rounded-lg shadow sm:w-64'
                                            label='Business Model'
                                            labelClass='mb-4'
                                            value={values.businessModel}
                                            onChange={(val) => setValues({ ...values, businessModel: val })}
                                        />
                                        <FileField
                                            help={<div><p>Your pitch deck is a visual presentation that provides investors with an overview of your business. It should be concise, engaging, and cover essential topics like the problem you're solving, your solution, market size, business model, traction, and team. Typically, successful pitch decks are between 10-15 slides.</p><p className="mt-5">Remember to keep it visually appealing and avoid clutter. Formats accepted: PDF, PPT, PPTX.</p></div>}
                                            className='flex flex-col items-start w-40 py-6 pl-3 mx-10 mt-6 rounded-lg shadow sm:w-64'
                                            label='Pitch Deck'
                                            labelClass='mb-4'
                                            value={values.pitchDeck}
                                            onChange={(val) => setValues({ ...values, pitchDeck: val })}
                                        />
                                    </div>
                                </>
                            )}

                            {(!isOnboarding || stage === 7) && (
                                <div>
                                    <h3 className='mt-10 mb-4 text-2xl font-semibold leading-6 text-blue-500'>Pitch Video</h3>
                                    <div>
                                        <p className='text-sm font-light text-neutral-500'>Your DreamRocket video is your chance to pitch to investors. You have 30-60 seconds to capture their attention and get them to invest.</p>
                                        <p className='text-sm font-light text-neutral-500'>Make sure your video is well lit and in focus.</p>
                                        <p className='text-sm font-light text-neutral-500'>Once you've submitted your video we will review it for quality before it goes live on the platform.</p>
                                    </div>
                                    <div className="text-sm text-neutral-700">Recommended Aspect Ratio 9:16</div>
                                    <S3Field required label='Video' value={values.videoUrl} onChange={(val) => setValues({ ...values, videoUrl: val })} />
                                </div>
                            )}
                        </div>
                        {props.isOnboarding && <div className='flex flex-col items-center justify-center py-4 shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.25)] align-center rounded-t-2xl'>
                            {missingFields.length > 0 && <div className='mb-4 font-normal'>Please complete all required fields: {missingFields.join(', ')}</div>}
                            <div className='flex items-center justify-center align-center'>
                                {stage !== 1 && stage < 8 && (
                                    <button type="button" onClick={decreaseStage} className='inline-flex items-center justify-center w-24 px-4 py-2 mr-6 text-sm font-medium text-white bg-blue-500 border border-transparent shadow-sm cursor-pointer rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
                                        Previous
                                    </button>
                                )}
                                {stage < 7 && (
                                    <button type="button" onClick={(e) => increaseStage(e, stage, values)} className='inline-flex items-center justify-center w-24 px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent shadow-sm cursor-pointer rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
                                        Next
                                    </button>
                                )}
                                {stage === 7 && (
                                    <button type="button" onClick={!values.videoUrl ? missingVideo : validateThenSubmit(values, submit)} className='inline-flex items-center justify-center w-24 px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent shadow-sm cursor-pointer rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>}

                        {!props.isOnboarding && <div className='flex flex-col items-center justify-center py-4 shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.25)] align-center rounded-t-2xl'>
                            {missingFields.length > 0 && <div className='mb-4 font-normal'>Please complete all required fields: {missingFields.join(', ')}</div>}
                            <div className='flex items-center justify-center space-x-4 align-center'>
                                <button onClick={(e) => validateThenSave(values, submit)} className='inline-flex items-center justify-center w-64 px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent shadow-sm cursor-pointer rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
                                    Save & Continue Later
                                </button>
                                <button type="button" onClick={(e) => validateThenSubmit(values, submit)} className='inline-flex items-center justify-center w-24 px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent shadow-sm cursor-pointer rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
                                    Submit
                                </button>


                            </div>
                        </div>}


                    </div>
                )}
            </FormWrapper>
        </>
    )
}
