import HelpPopup from "../../components/help-popup";

export default function TextAreaField({ label, value, onChange, className, inputClass, labelClass, onBlur, placeholder, disabled, maxLength, help }) {
    return (
        <div className={className + ' mt-4'}>
            <label className={`relative block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>{label} {help && <HelpPopup>{help}</HelpPopup>}</label>
            <div className='mt-1'>

                <textarea maxLength={maxLength} disabled={disabled} placeholder={placeholder} onBlur={onBlur} value={value} onChange={(e) => onChange(e.target.value)} rows={4} className={`block w-full rounded-lg p-3 border-0 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-2 focus:ring-blue-400 sm:py-1.5 sm:text-base sm:leading-6 ${inputClass}`} />

            </div>
        </div>
    )
}
