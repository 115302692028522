import { Popover } from '@headlessui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

export default function HelpPopup({ text, children }) {
    return (
        <Popover className='absolute inline'>
            <Popover.Button>
                <div className='absolute top-0 z-10 bg-blue-500 rounded-full left-2'>
                    <QuestionMarkCircleIcon className='w-6 h-6 text-white' />
                </div>
            </Popover.Button>

            {children && <Popover.Panel className='absolute z-40 p-3 text-sm border shadow w-96 -left-20 rounded-xl bg-neutral-100 text-neutral-700'>{children}</Popover.Panel>}
            {!children && <Popover.Panel className='absolute z-40 p-3 text-sm text-center border shadow w-96 -left-20 rounded-xl bg-neutral-100 text-neutral-700'>{text}</Popover.Panel>}
        </Popover>
    )
}
