import { useNavigate, useParams } from 'react-router-dom'

import PageHeader from '../components/page-header'

import { MultiSelectField, PasswordField, SelectField, TextAreaField, TextField } from './fields'
import ImageField from './fields/image-upload'
import FormWrapper from './form-wrapper'

export default function UserForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    return (
        <div>
            <PageHeader headline='Manage User'></PageHeader>
            <FormWrapper url='admin/user' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <div className='grid grid-cols-2 gap-4'>
                            <TextField label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                            <TextField label='Email' value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>
                        </div>
                        <ImageField label='Profile Picture' value={values.profilePicture} onChange={(val) => setValues({ ...values, profilePicture: val })} />

                        <div className='grid grid-cols-2 gap-4'>
                            <SelectField optionListName={'countries'} label='Country' value={values.country} onChange={(val) => setValues({ ...values, country: val })} />
                            <TextField label='City' value={values.city} onChange={(val) => setValues({ ...values, city: val })}></TextField>
                        </div>
                        <SelectField
                            options={[
                                { text: 'Retail: $10-$10,000', value: 'retail' },
                                { text: 'Angel: $10,001-$100,000', value: 'angel' },
                                { text: 'Venture: $10,001 and above', value: 'venture' },
                            ]}
                            label='Investment Range'
                            value={values.investmentRange}
                            onChange={(val) => setValues({ ...values, investmentRange: val })}
                        />
                        <MultiSelectField optionListName={'industry'} label='Preferred Industry/Vertical' value={values.preferredIndustries} onChange={(val) => setValues({ ...values, preferredIndustries: val })} />
                        <MultiSelectField optionListName={'stage'} label='Stage Preference' value={values.stagePreferences} onChange={(val) => setValues({ ...values, stagePreferences: val })} />

                        <TextAreaField label='Bio' value={values.bio} onChange={(val) => setValues({ ...values, bio: val })} />
                        <TextAreaField label='Investment Philosophy' value={values.investmentPhilosophy} onChange={(val) => setValues({ ...values, investmentPhilosophy: val })} />

                        <TextAreaField label='Notable Investments' value={values.notableInvestments} onChange={(val) => setValues({ ...values, notableInvestments: val })} />
                        <TextAreaField label='Successful Exits' value={values.successfulExits} onChange={(val) => setValues({ ...values, successfulExits: val })} />

                        <TextAreaField label='Media & Publications' value={values.mediaPublications} onChange={(val) => setValues({ ...values, mediaPublications: val })} />

                        <TextAreaField label='Affiliated Groups' value={values.affiliatedGroups} onChange={(val) => setValues({ ...values, affiliatedGroups: val })} />

                        <SelectField label='Company' optionListName={'companies'} value={values.company} onChange={(val) => setValues({ ...values, company: val })} />

                        <hr className='my-5' />
                        <PasswordField autoComplete='new-password' label='New Password' value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
