export default function TextField({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur, type, required, disabled }) {
    return (
        <div className={className + ' mt-2'}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </label>
            <div className='mt-1'>

                <input disabled={disabled} required={required} type={type ? type : 'text'} onBlur={onBlur} placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} className={`block w-full rounded-lg border-0 p-3 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-2 focus:ring-blue-400 sm:text-base sm:leading-6 ${inputClass}`} />

            </div>
        </div>
    )
}
